import { AppEffect } from "~store/store";
import { ActionType } from "~store/action-types";
import { Effect } from "~lib/redux-effects";
import { doImport } from "~export/import";
import { AppActions } from "~state";
import { UiModalActions, SimpleModal } from "~store/ui/modal/actions";
import { ExportJson } from "~export/schema";
import { ProjectActions } from "./actions";
import { UiStore } from "~store/ui/store";
import { example } from "~export/example";

async function blobToText(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsText(blob);
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reject(reader.error);
    };

    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
}

const openFile: AppEffect = store => action => {
  if (action.type === ActionType.PROJECT_OPEN_FROM_FILE) {
    // Show file open dialog
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = false;
    fileInput.accept = ".twigtask.json,.json";
    fileInput.click();

    fileInput.addEventListener("input", async () => {
      if (fileInput.files != null && fileInput.files.length >= 1) {
        const file = fileInput.files[0];

        const contents = JSON.parse(await blobToText(file));
        if (contents.version === "v1") {
          // TODO: Safely parse this and error if unexpected
          const exportJson = contents as ExportJson;

          store.dispatch({
            type: ActionType.PROJECT_LOAD,
            closeConfirmed: false,
            project: { t: "from-export", data: exportJson },
          });
        }
      }
    });
  }
};

const warnOnClose = (legacyApp: AppActions): AppEffect => store => {
  window.addEventListener("beforeunload", e => {
    if (legacyApp.domain.store.isDirty.get()) {
      e.preventDefault();
      e.returnValue =
        "You have unsaved changes, are you sure you want to leave without saving?";
      store.dispatch(UiStore.pathSet({ path: "/save" }));
    }
  });
  return () => {};
};

const loadProject = (legacyApp: AppActions): AppEffect => store => action => {
  if (action.type === ActionType.PROJECT_LOAD) {
    if (
      action.closeConfirmed ||
      legacyApp.store.domain.isOpen.get() === false ||
      legacyApp.store.domain.isDirty.get() === false
    ) {
      legacyApp.store.domain.reset();
      switch (action.project.t) {
        case "from-export":
          // Clear out the current project
          doImport(action.project.data, legacyApp.domain);
          store.dispatch(UiStore.projectLoaded({}));
          return;
        case "example":
          doImport(example, legacyApp.domain);
          store.dispatch(UiStore.projectLoaded({}));
          store.dispatch(
            UiModalActions.show({
              modal: { t: UiModalActions.ModalType.TUTORIAL },
            })
          );
          return;
        case "blank":
          legacyApp.openBlankProject();
          store.dispatch(UiStore.projectLoaded({}));
          return;
      }
    } else {
      // Confirmation is needed
      store.dispatch(
        UiModalActions.show({
          modal: {
            t: UiModalActions.ModalType.SIMPLE,
            heading: "Close current project?",
            text:
              "If you open a new project, your current project will be " +
              "closed and any unsaved changes may be lost. Are you sure " +
              "you want to close your current project?",
            defaultAction: undefined,
            buttons: [
              {
                hint: SimpleModal.ButtonHint.SECONDARY,
                label: "Cancel",
                action: undefined,
              },
              {
                hint: SimpleModal.ButtonHint.DANGER,
                label: "Close current project",
                action: ProjectActions.load({
                  project: action.project,
                  closeConfirmed: true,
                }),
              },
            ],
          },
        })
      );
    }
  }
};

export const projectEffects = (legacyApp: AppActions) =>
  Effect.all(loadProject(legacyApp), warnOnClose(legacyApp), openFile);
