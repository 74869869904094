import { ActionType } from "~store/action-types";
import { actionCreator } from "~lib/action-creators";
import { RootAction } from "~store/actions";

export namespace SimpleModal {
  export enum ButtonHint {
    PRIMARY = "primary",
    DANGER = "danger",
    SECONDARY = "secondary",
  }

  export type Button = {
    label: string;
    hint: ButtonHint;
    action: RootAction | undefined;
  };

  export type Type =
    | {
        t: UiModalActions.ModalType.SIMPLE;
        heading: string;
        text: string;
        buttons: Button[];
        defaultAction: RootAction | undefined;
      }
    | { t: UiModalActions.ModalType.TUTORIAL };
}

export namespace UiModalActions {
  export enum ModalType {
    SIMPLE,
    TUTORIAL,
  }

  export type Modal = SimpleModal.Type;

  // Actions
  export type Show = { type: ActionType.UI_MODAL_SHOW; modal: Modal };
  export type Hide = { type: ActionType.UI_MODAL_HIDE };

  export const show = actionCreator<Show, ActionType>(ActionType.UI_MODAL_SHOW);
  export const hide = actionCreator<Hide, ActionType>(ActionType.UI_MODAL_HIDE);

  export type Action = Show | Hide;
}
