import { useTheme } from "../themes/provider";
import * as React from "react";
import { stylesheet } from "typestyle";
import { gridBaseline } from "../base/styles";

const styles = stylesheet({
  appHeader: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
    padding: gridBaseline,
  },
  image: {
    height: "100%",
  },
});

export const AppHeader = () => {
  const theme = useTheme();
  return (
    <div
      className={styles.appHeader}
      style={{ backgroundColor: theme.backgroundF }}
    >
      <img className={styles.image} src={theme.logo} />
    </div>
  );
};
