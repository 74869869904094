import { DomainStore } from "../../data/domain";
import { action } from "mobx";
import { TaskDependencyId, TaskId } from "../../../persistence/log/identifiers";
import { Result } from "../../../base/result";
import { ActionErrorTag } from "../../../persistence/log/errors";
import { expect } from "../../../util";

export class DependencyActions {
  store: DomainStore;

  constructor(store: DomainStore) {
    this.store = store;
  }

  wouldCreateCycle(left: TaskId, right: TaskId): boolean {
    let seenTasks = new Set([left]);
    let searchTasks = [right];
    while (searchTasks.length > 0) {
      const taskId = expect(searchTasks.pop());

      // We're back at the first task, there's a cycle
      if (taskId === left) {
        return true;
      }

      if (seenTasks.has(taskId)) {
        // Visited this task before, no need to visit it again
        continue;
      }
      seenTasks.add(taskId);

      const task = expect(this.store.tasks.items.get(taskId));
      // Potential for perf improvement - we shouldn't need to
      // search through all deps to find only right hand deps.
      // Store them seperately instead
      for (let depId of task.dependencies) {
        const dep = expect(this.store.dependencies.items.get(depId));
        // We only care about right hand deps
        if (dep.left === taskId) {
          searchTasks.push(dep.right);
        }
      }
    }

    return false;
  }

  create = action(
    (
      id: TaskDependencyId,
      task: TaskId,
      dependsOn: TaskId
    ): Result.T<void, ActionErrorTag> => {
      // A task can't depend on itself
      if (task === dependsOn) {
        return Result.err(ActionErrorTag.TASK_DEPENDS_ON_SELF);
      }

      const existingDep = this.find(dependsOn, task);
      if (existingDep != null) {
        // Dependency already exists, don't create another
        return Result.err(ActionErrorTag.TASK_DEPENDENCY_EXISTS);
      }

      if (this.wouldCreateCycle(dependsOn, task)) {
        return Result.err(ActionErrorTag.TASK_DEPENDENCY_CYCLE);
      }

      const leftTask = this.store.tasks.items.get(dependsOn);
      const rightTask = this.store.tasks.items.get(task);

      if (leftTask == null || rightTask == null) {
        return Result.err(ActionErrorTag.TASK_NOT_FOUND);
      }

      this.store.dependencies.items.set(id, {
        id,
        right: task,
        left: dependsOn,
      });

      rightTask.dependencies.add(id);
      leftTask.dependencies.add(id);

      return Result.ok(undefined);
    }
  );

  delete = action((depId: TaskDependencyId) => {
    // Remove it from the associated tasks too
    const dep = expect(this.store.dependencies.items.get(depId));
    const leftTask = expect(this.store.tasks.items.get(dep.left));
    const rightTask = expect(this.store.tasks.items.get(dep.right));

    leftTask.dependencies.delete(depId);
    rightTask.dependencies.delete(depId);
    this.store.dependencies.items.delete(depId);
  });

  find = (left: TaskId, right: TaskId): TaskDependencyId | undefined => {
    const task = expect(this.store.tasks.items.get(left));
    for (const depId of task.dependencies) {
      const dep = expect(this.store.dependencies.items.get(depId));
      if (dep.left === left && dep.right === right) {
        return depId;
      }
    }

    // Not found
    return undefined;
  };
}
