import * as React from "react";

type Props = {
  left: number;
  top: number;
  onClickOutside(): void;
};

export const ContextMenu: React.FunctionComponent<Props> = props =>  {
  const menuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        menuRef.current != null
        && e.currentTarget != null
        && !menuRef.current.contains(e.target as Node)
      ) {
        props.onClickOutside();
      }
    };
    document.addEventListener('mousedown', handler);

    return function cleanup() {
      document.removeEventListener('mousedown', handler);
    }
  });

  return (
    <div
      ref={menuRef}
      className="posAbsolute border-1 border-col-fg-b pointer-events-auto bg-col-bg-a"
      style={{ left: props.left, top: props.top }}
    >
      {props.children}
    </div>
  );
};

type ItemProps = {
  onClick(x: number, y: number): void;
};

export const ContextMenuItem: React.FunctionComponent<ItemProps> = props => {
  const onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    props.onClick(e.clientX, e.clientY);
  };

  return <a className="block pad-3 text-normal hover-bg-col-bg-c" href="#" onClick={onClick}>
    {props.children}
  </a>
};

export const ContextMenuContainer: React.FunctionComponent = props => {
  return (
    <div
      className="posAbsolute pos-top-left fillDimensions pointer-events-none"
      style={{ top: 0, left: 0 }}
    >
      {props.children}
    </div>
  );
}
