import * as React from "react";
import { Vec2 } from "../../base/vec2";
import classnames from "classnames";

type Props = {
  viewSize: Vec2.T;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onWheel?: (e: React.WheelEvent<HTMLDivElement>) => void;
  className?: string;
};

/**
 * Only handle events directly on the overlay, ignore child elements
 */
const currentTargetOnly = <
  T extends React.MouseEvent<HTMLDivElement> | React.WheelEvent<HTMLDivElement>
>(
  handler?: (e: T) => void
) => {
  if (handler == null) return;

  return (e: T) => {
    if (e.currentTarget === e.target) {
      handler(e);
    }
  };
};

export const Overlay = (props: React.PropsWithChildren<Props>) => (
  <div
    className={classnames("posAbsolute", props.className)}
    onClick={currentTargetOnly(props.onClick)}
    onMouseLeave={props.onMouseLeave}
    onMouseDown={currentTargetOnly(props.onMouseDown)}
    onMouseMove={currentTargetOnly(props.onMouseMove)}
    onMouseUp={currentTargetOnly(props.onMouseUp)}
    onWheel={currentTargetOnly(props.onWheel)}
    style={{
      width: props.viewSize.x,
      height: props.viewSize.y,
      overflow: "hidden",
    }}
  >
    {props.children}
  </div>
);
