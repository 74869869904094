import { AppEffect } from "~store/store";
import { ActionType } from "~store/action-types";
import { Effect } from "~lib/redux-effects";
import { UiFileActions } from "./actions";
import { waitlistSignup } from "~api/internal/waitlist";
import { expect } from "~util";

const emailRegexp = /[^@]+@[^@.]+\.[^@]+/;

const waitlistRequest: AppEffect = store => async action => {
  if (action.type === ActionType.UI_FILE_WAITLIST_SIGNUP_REQUEST) {
    if (!emailRegexp.test(action.emailAddress)) {
      return store.dispatch(
        UiFileActions.waitlistSignupError({
          message: "Please enter a valid email address",
        })
      );
    }

    const response = await waitlistSignup({
      email_address: action.emailAddress,
    });

    if (response.t === "ServerError") {
      return store.dispatch(
        UiFileActions.waitlistSignupError({
          message: "We're having trouble connecting, please try again later.",
        })
      );
    }

    if (response.t === "Error") {
      return store.dispatch(
        UiFileActions.waitlistSignupError({
          message: expect(response.user_message),
        })
      );
    }

    store.dispatch(
      UiFileActions.waitlistSignupSuccess({ emailAddress: action.emailAddress })
    );
  }
};

export const uiFileEffects = Effect.all(waitlistRequest);
