import { GraphViewId, TaskId } from "./identifiers";

export type Position = {
  x: number;
  y: number;
};

export enum ActionType {
  TASK_CREATE = "task_create",
  TASK_DELETE = "task_delete",
  TASK_RENAME = "task_rename",
  TASK_DESCRIPTION_UPDATE = "task_description_update",
  TASK_DEPENDENCY_CREATE = "task_dependency_create",
  TASK_DEPENDENCY_DELETE = "task_dependency_delete",
  /**
   * @deprecated
   */
  TASK_COMPLETE = "task_complete",
  TASK_STATUS_UPDATE = "task_status_update",
  GRAPH_VIEW_CREATE = "graph_view_create",
  GRAPH_VIEW_RENAME = "graph_view_rename",
  GRAPH_VIEW_DELETE = "graph_view_delete",
  GRAPH_VIEW_TASK_ADD = "graph_view_task_add",
  GRAPH_VIEW_TASK_MOVE = "graph_view_task_move",
  GRAPH_VIEW_TASK_HIDE = "graph_view_task_hide",
}

export type GraphViewCreateAction = {
  type: ActionType.GRAPH_VIEW_CREATE;
  id: GraphViewId;
  label: string;
};

export type GraphViewRenameAction = {
  type: ActionType.GRAPH_VIEW_RENAME;
  graphViewId: GraphViewId;
  label: string;
};

export type GraphViewDeleteAction = {
  type: ActionType.GRAPH_VIEW_DELETE;
  graphViewId: GraphViewId;
};

export type GraphViewTaskAddAction = {
  type: ActionType.GRAPH_VIEW_TASK_ADD;
  graphViewId: GraphViewId;
  taskId: TaskId;
  position: { x: number; y: number };
};

export type GraphViewTaskMoveAction = {
  type: ActionType.GRAPH_VIEW_TASK_MOVE;
  graphViewId: GraphViewId;
  taskId: TaskId | TaskId[];
  moveBy: { x: number; y: number };
};

export type GraphViewTaskHideAction = {
  type: ActionType.GRAPH_VIEW_TASK_HIDE;
  graphViewId: GraphViewId;
  taskIds: TaskId[];
};

export type CreateTaskAction = {
  type: ActionType.TASK_CREATE;
  id: TaskId;
  title: string;
  dependsOn?: TaskId;
  dependedOnBy?: TaskId;
};
export type RenameTaskAction = {
  type: ActionType.TASK_RENAME;
  id: TaskId;
  title: string;
};
export type CreateDependencyAction = {
  type: ActionType.TASK_DEPENDENCY_CREATE;
  task: TaskId;
  dependsOn: TaskId;
};
export type DeleteDependencyAction = {
  type: ActionType.TASK_DEPENDENCY_DELETE;
  left: TaskId;
  right: TaskId;
};
/**
 * @deprecated Use UpdateStatusAction instead
 */
export type CompleteTaskAction = {
  type: ActionType.TASK_COMPLETE;
  task: TaskId;
  complete: boolean;
};
export type UpdateTaskDescriptionAction = {
  type: ActionType.TASK_DESCRIPTION_UPDATE;
  task: TaskId;
  description: string;
};
export type DeleteTaskAction = {
  type: ActionType.TASK_DELETE;
  task: TaskId;
};
export type UpdateStatusAction = {
  type: ActionType.TASK_STATUS_UPDATE;
  task: TaskId;
  status: "none" | "in-progress" | "complete";
};

export type DomainAction =
  | GraphViewCreateAction
  | GraphViewRenameAction
  | GraphViewDeleteAction
  | GraphViewTaskAddAction
  | GraphViewTaskMoveAction
  | GraphViewTaskHideAction
  | DeleteTaskAction
  | CreateTaskAction
  | CreateDependencyAction
  | DeleteDependencyAction
  | UpdateTaskDescriptionAction
  | CompleteTaskAction
  | RenameTaskAction
  | UpdateStatusAction;
