import { AppEffect } from "../store";
import { Effect } from "~lib/redux-effects";
import { ActionType } from "~store/action-types";
import { AppActions } from "~state";
import { generateId, GraphViewId } from "~persistence/log/identifiers";
import { UiStore } from "./store";
import { uiFileEffects } from "./file/effects";
import {
  DomainAction,
  ActionType as LegacyActionType,
} from "~persistence/log/action_definitions";

const setWindowLocation: AppEffect = store => action => {
  if (action.type === ActionType.UI_PATH_SET) {
    window.location.hash = action.path;
    store.dispatch({ type: ActionType.UI_PATH_CHANGED, path: action.path });
  }
};

const handleLocationChange: AppEffect = store => {
  window.addEventListener("hashchange", () => {
    const path = window.location.hash.slice(1);
    store.dispatch({ type: ActionType.UI_PATH_CHANGED, path });
  });

  return () => {};
};

const projectLoaded = (legacyApp: AppActions): AppEffect => store => action => {
  if (action.type === ActionType.UI_PROJECT_LOADED) {
    if (legacyApp.store.domain.graphViews.items.size < 1) {
      const id = generateId<GraphViewId>();
      const domainAction: DomainAction = {
        type: LegacyActionType.GRAPH_VIEW_CREATE,
        id,
        label: "Graph View",
      };
      legacyApp.saveAction(domainAction);
    }

    // Get the first graph view id and show it
    const id = legacyApp.store.domain.graphViews.items.keys().next().value;
    store.dispatch(UiStore.pathSet({ path: `/graph/${id}` }));
  }
};

export const uiEffects = (legacyApp: AppActions) =>
  Effect.all(
    uiFileEffects,

    setWindowLocation,
    handleLocationChange,
    projectLoaded(legacyApp)
  );
