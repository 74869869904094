export type ActionId = string & { _actionId: never };
export type TaskId = string & { __taskId: never };
export type GraphViewId = string & { __graphViewId: never };
export type TaskDependencyId = string & { __taskDependencyId: never };

type Id = ActionId | TaskId | TaskDependencyId | GraphViewId;

export const generateId = <a extends Id>(): a =>
  Math.random()
    .toString(36)
    .substring(7) as a;
