import { Vec2 } from "../../base/vec2";
import * as React from "react";
import { command, compose, move, close } from "../../base/svg_commands";
import { TaskAnchor } from "../../state/data/domain/task_dependencies";
import classNames = require("classnames");

type Props = {
  pos: Vec2.T;
  state: "none" | "selected";
  anchor: TaskAnchor.T;
};

const pathIn = (pos: Vec2.T) =>
  compose(
    move(pos.x, pos.y),
    command("l 28 0"),
    command("l 0 34"),
    command("l -28 0"),
    command("l 10 -17"),
    command("l -10 -17"),
    close()
  );

const pathOut = (pos: Vec2.T) =>
  compose(
    move(pos.x, pos.y),
    command("l 18 0"),
    command("l 18 17"),
    command("l -18 17"),
    command("l -18 0"),
    close()
  );

export const DependencyAnchor = (props: Props) => {
  const pathFn = props.anchor === "in" ? pathIn : pathOut;
  return (
    <>
      <path
        className={classNames(
          props.state === "selected"
            ? "svg-fill-col-bg-c"
            : "svg-fill-col-bg-a",
          "svg-str-col-fg-b"
        )}
        d={pathFn(props.pos)}
      />
    </>
  );
};
