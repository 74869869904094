import * as React from "react";
import { observer } from "mobx-react-lite";
import { graphViewCreate } from "../persistence/log/actions";
import { AppActions } from "../state";
import { NavItem, SideNav, NavItemHeader } from "./ui/SideNav";
import { GraphViewId } from "../persistence/log/identifiers";
import {
  IconCommonProps,
  GoalIcon,
  ListIcon,
  GraphIcon,
  AddIcon,
  OpenIcon,
  DownloadIcon,
} from "~components/Icons";
import { useDispatch, useSelector } from "~store/store";
import { UiStore } from "~store/ui/store";
import { ListButton } from "./ui/button/Button";
import { stylesheet } from "typestyle";
import { gridBaseline } from "~base/styles";

const styles = stylesheet({
  navActionsContainer: {
    padding: gridBaseline,
  },
});

enum NavItemType {
  Item,
  Header,
}

type NavItem =
  | {
      type: NavItemType.Item;
      label: string;
      Icon: React.ComponentType<IconCommonProps>;
      path: string;
    }
  | {
      type: NavItemType.Header;
      label: string;
    };

export const NavBar = observer(({ app }: { app: AppActions }) => {
  const currentPath = useSelector(state => state.ui.path);

  const navItems: NavItem[] = [
    { type: NavItemType.Header, label: "Project" },
    { type: NavItemType.Item, label: "Load", Icon: OpenIcon, path: "/" },
    {
      type: NavItemType.Item,
      label: "Save",
      Icon: DownloadIcon,
      path: "/save",
    },
    { type: NavItemType.Header, label: "Task Views" },
    { type: NavItemType.Item, label: "Goals", Icon: GoalIcon, path: "/goals" },
    {
      type: NavItemType.Item,
      label: "Unblocked",
      Icon: ListIcon,
      path: "/unblocked",
    },
  ];
  const onAddViewClick = () => {
    let action = graphViewCreate("Untitled graph");
    app.domain.saveAction(action);
    app.store.reduxStore.dispatch(
      UiStore.pathSet({ path: `/graph/${action.id}` })
    );
  };
  const onMenuClick = (
    e: { clientX: number; clientY: number },
    id: GraphViewId
  ) => {
    const state = app.store.ui.state.get();
    if (state.t === "context_menu" && state.content.graphViewId === id) {
      app.ui.resetState();
      return;
    }
    app.store.ui.state.set({
      t: "context_menu",
      left: e.clientX,
      top: e.clientY,
      content: { t: "graph_view", graphViewId: id },
    });
  };
  const views = Array.from(app.domain.store.graphViews.items.values());
  const dispatch = useDispatch();
  return (
    <SideNav>
      {navItems.map((navItem, i) => {
        switch (navItem.type) {
          case NavItemType.Item:
            return (
              <NavItem
                href="/"
                Icon={navItem.Icon}
                key={i}
                active={currentPath === navItem.path}
                onClick={() =>
                  dispatch(
                    UiStore.pathSet({
                      path: navItem.path,
                    })
                  )
                }
                label={navItem.label}
              />
            );
          case NavItemType.Header:
            return <NavItemHeader>{navItem.label}</NavItemHeader>;
        }
      })}
      {views.map(graphView => {
        const state = app.store.ui.state.get();
        let editingValue =
          state.t === "view_name_editing" &&
          graphView.graphViewId === state.graphViewId
            ? state.value
            : undefined;

        const onEditStart = () => {
          app.ui.graphViewNameEdit(graphView.graphViewId);
        };
        const onEditChange = (val: string) => {
          app.ui.graphViewNameChange(val);
        };
        const onEditComplete = () => {
          app.ui.graphViewNameSave();
        };
        const onEditCancel = () => {
          app.ui.resetState();
        };

        const path = `/graph/${graphView.graphViewId}`;
        const onClick = () => {
          app.ui.showGraphView(graphView.graphViewId);
        };

        return (
          <NavItem
            key={graphView.graphViewId}
            href={path}
            onClick={onClick}
            onMenu={e => onMenuClick(e, graphView.graphViewId)}
            editable={{
              editing: editingValue != null,
              onEditStart,
              onEditCancel,
              onEditChange,
              onEditComplete,
            }}
            Icon={GraphIcon}
            label={editingValue || graphView.label.get()}
            active={currentPath === path}
          />
        );
      })}

      <div className={styles.navActionsContainer}>
        <ListButton Icon={AddIcon} onClick={onAddViewClick}>
          New Graph
        </ListButton>
      </div>
    </SideNav>
  );
});
