import { RootAction } from "~store/actions";
import { ActionType } from "~store/action-types";

export namespace UiFileStore {
  export type State = {
    waitlistSignupState:
      | { t: "ready" }
      | { t: "pending" }
      | { t: "error"; message: string }
      | { t: "successful"; emailAddress: string };
  };
  export const initialState = (): State => ({
    waitlistSignupState: { t: "ready" },
  });

  export const reducer = (
    state: State = initialState(),
    action: RootAction
  ) => {
    switch (action.type) {
      case ActionType.UI_FILE_WAITLIST_SIGNUP_REQUEST:
        return {
          ...state,
          waitlistSignupState: { t: "pending" },
        };
      case ActionType.UI_FILE_WAITLIST_SIGNUP_RESET:
        return {
          ...state,
          waitlistSignupState: { t: "ready" },
        };
      case ActionType.UI_FILE_WAITLIST_SIGNUP_ERROR:
        return {
          ...state,
          waitlistSignupState: { t: "error", message: action.message },
        };
      case ActionType.UI_FILE_WAITLIST_SIGNUP_SUCCESS:
        return {
          ...state,
          waitlistSignupState: {
            t: "successful",
            emailAddress: action.emailAddress,
          },
        };
    }
    return state;
  };
}
