import {
  createStore as reduxCreateStore,
  applyMiddleware,
  Store as ReduxStore,
  PreloadedState,
} from "redux";
import { State, rootReducer, initialState } from "./reducer";
import { RootAction } from "./actions";
import { Effect, effectsMiddleware } from "~lib/redux-effects";
import {
  useDispatch as useDispatchExtern,
  useSelector as useSelectorExtern,
  TypedUseSelectorHook,
} from "react-redux";
import { Dispatch } from "react";
import { uiEffects } from "./ui/effects";
import { projectEffects } from "./project/effects";
import { AppActions } from "~state";

export const useDispatch = (): Dispatch<RootAction> => useDispatchExtern();
export const useSelector: TypedUseSelectorHook<State> = useSelectorExtern;

export type Store = ReduxStore<State, RootAction>;
export type AppEffect = Effect<State, RootAction>;

const logEffect = (prefix: string): AppEffect => _store => action => {
  console.log(prefix, action);
};

declare var window: Window & {
  state: any;
};

const updateDebugStore: AppEffect = store => _action => {
  window.state = store.getState();
};

type Deps = {
  legacyApp: AppActions;
};

const rootEffect = (deps: Deps) =>
  Effect.all(
    logEffect("Action:"),
    updateDebugStore,
    uiEffects(deps.legacyApp),
    projectEffects(deps.legacyApp)
  );

// Just used for the time being while removing the legacy mobx store
export function createDummyStore(): Store {
  const store: Store = reduxCreateStore<State, RootAction, unknown, unknown>(
    (state, action) => {
      console.error("Action submitted to dummy store", action);
      return state || initialState();
    },
    initialState() as PreloadedState<State>
  );

  return store;
}

export function createStore(deps: Deps): Store {
  const store: Store = reduxCreateStore<State, RootAction, unknown, unknown>(
    rootReducer,
    initialState() as PreloadedState<State>,
    applyMiddleware(effectsMiddleware(rootEffect(deps)))
  );

  return store;
}
