import * as React from "react";
import { DarkTheme } from "./dark";
import { Theme } from "./theme";

//const defaultTheme = matchMedia("(prefers-color-scheme: dark)").matches
//  ? DarkTheme
//  : LightTheme;
const defaultTheme = DarkTheme;

export const ThemeProvider = React.createContext(defaultTheme);

export type StyleMapper<T> = (theme: Theme) => T;

/**
 * Uses the theme from React context, then uses the provided style
 * generation function to pass a `styles` prop to the component,
 * containing the generated class names.
 */
export const withStyles = function<T>(styleMapper: StyleMapper<T>) {
  return function<P>(
    C: React.ComponentType<P & { styles: T }>
  ): React.FunctionComponent<P> {
    return (props: P) => {
      const theme = React.useContext(ThemeProvider);
      const styles = React.useMemo(() => styleMapper(theme), [theme]);

      return React.createElement(C, { ...props, styles });
    };
  };
};

export const useTheme = (): Theme => React.useContext(ThemeProvider);
