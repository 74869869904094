import { Theme } from "./theme";
import logo from "~/assets/logo-dark.png";

export const DarkTheme: Theme = {
  backgroundA: "#171717",
  backgroundB: "#202020",
  backgroundC: "#2B1C43",
  backgroundD: "#393650",
  backgroundE: "#422D64",
  backgroundF: "#4A4375",
  backgroundG: "#6F66A3",

  /* Foreground colours */
  foregroundA: "#fad8ff",
  foregroundB: "#7f6e82",

  /* Accent colours */
  accentA: "#7353ba",
  accentB: "#faa6ff",

  logo,
};
