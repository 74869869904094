import * as React from "react";
import { Vec2 } from "../../base/vec2";
import classNames = require("classnames");

type Props = {
  from: Vec2.T;
  to: Vec2.T;
  style?: "normal" | "pending" | "selected";
};

export const Connector = ({ from, to, style }: Props) => (
  <path
    d={
      `M ${from.x} ${from.y + 20} ` +
      `C ${from.x + 20} ${from.y + 20}, ` +
      `${to.x - 20} ${to.y + 20}, ` +
      `${to.x} ${to.y + 20}`
    }
    className={classNames(
      style === "pending" ? "svg-str-col-fg-b" : "svg-str-col-fg-a",
      {
        "svg-str-wd-3 svg-str-col-acc-b svg-str-dash svg-str-dash-anim":
          style === "selected",
      }
    )}
    strokeWidth={2}
    fill="transparent"
  />
);
