import { UiActions } from "../ui";
import { DomainStore } from "./domain";
import { UiStore } from "./ui";
import { Store } from "~store/store";

export class AppStore {
  domain: DomainStore;
  ui: UiStore;
  /**
   * @deprecated This shouldn't be used outside the legacy mobx store
   */
  reduxStore: Store;

  constructor(reduxStore: Store) {
    this.domain = new DomainStore();
    this.ui = new UiStore();
    this.reduxStore = reduxStore;
  }
}
