import * as React from "react";

type Props = {
  left: number;
  top: number;
  width: number;
  height: number;
} & Pick<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onMouseDown" | "onMouseUp" | "onMouseMove" | "onWheel"
>;

export const EventRegion = (props: Props) => {
  const { left, top, width, height, ...divProps } = props;

  return (
    <div
      {...divProps}
      style={{
        position: "absolute",
        left: props.left,
        top: props.top,
        width: props.width,
        height: props.height,
      }}
    />
  );
};
