import { GraphView } from "./Graph";
import * as React from "react";
import { GraphViewId } from "../../persistence/log/identifiers";
import { ErrorBoundary } from "../../components/ErrorBoundary";
import { stylesheet } from "typestyle";
import { Toolbar } from "./Toolbar";

type Props = {
  graphViewId: GraphViewId;
};

const styles = stylesheet({
  graphViewLayout: {
    display: "grid",
    width: "100%",
    height: "100%",
    gridTemplateColumns: "auto 48px",
  },
});

export const GraphViewLayout = (props: Props) => {
  return (
    <ErrorBoundary note="GraphView">
      <div className={styles.graphViewLayout}>
        <GraphView graphViewId={props.graphViewId} />
        <Toolbar />
      </div>
    </ErrorBoundary>
  );
};
