import * as React from "react";
import { useAppState } from "~app_provider";
import { Stack } from "~view/layout/Stack";
import { ListButton } from "~/view/ui/button/Button";
import { InfoIcon, NewIcon, OpenIcon, UndoIcon } from "~/components/Icons";
import { gridBaseline } from "~base/styles";
import { Heading1, Paragraph } from "~base/typography";
import { observer } from "mobx-react-lite";
import { useDispatch } from "~store/store";
import { ProjectActions } from "~store/project/actions";
import { FocusBox } from "~view/layout/FocusBox";

export const WelcomeScreen = observer(() => {
  const app = useAppState();

  const dispatch = useDispatch();
  const openBlankProject = () => {
    dispatch(
      ProjectActions.load({ project: { t: "blank" }, closeConfirmed: false })
    );
  };
  const openProjectLocally = () => {
    dispatch(ProjectActions.openFromFile({}));
  };
  const openExample = () => {
    dispatch(
      ProjectActions.load({ project: { t: "example" }, closeConfirmed: false })
    );
  };

  return (
    <FocusBox>
      <Stack direction="column" spacing={gridBaseline * 2}>
        <Heading1>Get Started</Heading1>
        <Paragraph>
          TwigTask helps you map out complex projects as a graph of
          dependencies, so it's clear what's blocked and what can be worked on
          next.
        </Paragraph>
        <Paragraph>
          If this is your first time using it, open the{" "}
          <strong>Example project</strong> to see what you can do with it.
        </Paragraph>
        <Stack direction="column">
          <ListButton Icon={InfoIcon} onClick={openExample}>
            Example project
          </ListButton>
        </Stack>
        <Stack direction="column">
          <ListButton Icon={NewIcon} onClick={openBlankProject}>
            Start a blank project
          </ListButton>
          <ListButton Icon={OpenIcon} onClick={openProjectLocally}>
            Open an existing project
          </ListButton>
          <ListButton Icon={UndoIcon} onClick={() => app.recoverProject()}>
            Recover last project
          </ListButton>
        </Stack>
      </Stack>
    </FocusBox>
  );
});

