import * as React from "react";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  floatRight: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  lhs: {
    flexGrow: 1,
    overflowX: "hidden",
  },
});

type Props = {
  Floater: React.ComponentType;
};

export const FloatRight: React.FunctionComponent<Props> = props => (
  <div className={styles.floatRight}>
    <div className={styles.lhs}>{props.children}</div>
    <div>{<props.Floater />}</div>
  </div>
);
