import * as React from "react";
import classNames from "classnames";
import { Button } from "./button/Button";
import { stylesheet } from "typestyle";
import { gridBaseline } from "../../base/styles";
import { ChevronDownIcon, IconCommonProps } from "~components/Icons";
import { Text } from "~base/typography";

export const SideNav: React.ComponentType = props => (
  <div className="bg-sec">{props.children}</div>
);

const styles = stylesheet({
  navItem: {
    boxSizing: "border-box",
    padding: "1px 0",
    $nest: {
      "&:hover": {
        backgroundColor: "var(--col-bg-b)",
      },
    },
  },
  navItemHeader: {
    padding: gridBaseline,
  },
  button: {
    width: gridBaseline * 6,
  },
});

type NavItemProps = {
  active: boolean;
  Icon: React.ComponentType<IconCommonProps>;
  href?: string;
  label: string;
  onClick(): void;
  onMenu?(e: { clientX: number; clientY: number }): void;

  editable?: {
    editing: boolean;
    onEditStart(): void;
    onEditCancel(): void;
    onEditComplete(val: string): void;
    onEditChange(val: string): void;
  };
};

type HeaderProps = {};

export const NavItemHeader: React.ComponentType<HeaderProps> = props => {
  return (
    <h1 className={styles.navItemHeader}>
      <Text level="p" muted={true}>
        {props.children}
      </Text>
    </h1>
  );
};

export const NavItem: React.ComponentType<NavItemProps> = props => {
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClick();
  };
  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault();

    // If there's a menu action, do the menu action
    if (e.button && props.onMenu) {
      props.onMenu(e);
    }

    return false;
  };
  const onDoubleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.editable && props.editable.onEditStart();
  };
  const onBlur = (e: React.FormEvent<HTMLInputElement>) => {
    props.editable && props.editable.onEditComplete(e.currentTarget.value);
  };
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    props.editable && props.editable.onEditChange(e.currentTarget.value);
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key);
    if (e.key === "Escape") {
      props.editable && props.editable.onEditCancel();
    }
    if (e.key === "Enter") {
      props.editable && props.editable.onEditComplete(e.currentTarget.value);
    }
  };

  const [hovered, setHovered] = React.useState(false);

  return (
    <a
      className={classNames(
        styles.navItem,
        "flex-container block text-normal",
        {
          "border-top border-bottom border-col-fg-a margin-vert--1px bg-col-bg-b":
            props.active,
        }
      )}
      href={props.href || "/"}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onContextMenu={onContextMenu}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="pad-3">
        <props.Icon size={gridBaseline * 3} />
      </div>
      <div className="flex-grow-1 pad-3">
        {props.editable && props.editable.editing ? (
          <input
            type="text"
            autoFocus={true}
            className="text-normal border-1 border-col-fg-a bg-col-bg-a"
            value={props.label}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        ) : (
          <span className="pad-left-2 pad-vert-1">{props.label}</span>
        )}
      </div>
      {props.onMenu && hovered && !props.editable?.editing && (
        <Button
          className={styles.button}
          onMouseDown={e => e.button === 0 && props.onMenu && props.onMenu(e)}
          onContextMenu={e => {
            e.preventDefault();
            e.stopPropagation();
            props.onMenu && props.onMenu(e);
            return false;
          }}
          noPadding={true}
          Icon={ChevronDownIcon}
          style="borderless"
        />
      )}
    </a>
  );
};
