import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  GraphViewId,
  TaskDependencyId,
  TaskId,
} from "../../persistence/log/identifiers";
import { expect } from "../../util";
import { TaskLookup } from "../TaskLookup";
import { TaskList, TaskSmall } from "../TaskList";
import { Vec2 } from "../../base/vec2";
import { HiddenDepIndicator } from "./HiddenDepIndicator";
import { DependencyAnchor } from "./DependencyAnchor";
import { EventRegion } from "./EventRegion";
import { Connector } from "./Connector";
import { MouseOpts } from "../../state/ui/graph_view";
import { Overlay } from "./Overlay";
import { TaskAnchor } from "../../state/data/domain/task_dependencies";
import { MIN_LOOKUP_HEIGHT, TASK_WIDTH } from "../../config/sizes";
import { SelectionMarquee } from "./SelectionMarquee";
import { GraphMouseTarget } from "../../state/data/ui/graph_view";
import { Task } from "../../state/data/domain/tasks";
import { graphViewTaskAdd } from "../../persistence/log/actions";
import { AddFirstTaskPrompt } from "./AddFirstTaskPrompt";
import { Measurable } from "../../components/Measurable";
import { useAppState } from "~app_provider";
import { stylesheet } from "typestyle";
import { Tool } from "~state/data/ui/graph_view";
import classnames from "classnames";

const styles = stylesheet({
  moveTool: {
    cursor: "grab",
  },
});

export const GraphView = observer((props: { graphViewId: GraphViewId }) => {
  const { graphViewId } = props;
  const app = useAppState();
  const activeTool = app.store.ui.graphView.activeTool.get();

  const onMeasurableResize = (size: Vec2.T) => {
    app.ui.graphView.updateViewSize(size);
  };

  const graphView = expect(app.store.domain.graphViews.items.get(graphViewId));
  const tasks = graphView.taskPositions.keys();
  const renderedDeps: Set<TaskDependencyId> = new Set();
  const eventTargets: React.ReactNode[] = [];

  const view_size = app.store.ui.graphView.viewSize.get();

  let connectors = [];

  const state = app.store.ui.graphView.state.get();
  const selection = app.store.ui.selection;

  const [draggingTask, setDraggingTask] = React.useState<
    { id: TaskId; start: Vec2.T; end: Vec2.T } | undefined
  >(undefined);

  const toViewCoords = app.ui.graphView.toViewCoords;

  const svgRef = React.useRef<SVGSVGElement>(null);

  const onBackgroundMouseDown = (
    e: React.MouseEvent<HTMLElement | SVGElement>
  ) => {
    e.preventDefault();
    app.ui.graphView.onMouseDown(getMouseOpts(svgRef, e), {
      t: "background",
    });
  };
  const onBackgroundMouseMove = (
    e: React.MouseEvent<HTMLElement | SVGElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    app.ui.graphView.onMouseMove(getMouseOpts(svgRef, e), {
      t: "background",
    });
  };
  const onBackgroundMouseUp = (
    e: React.MouseEvent<HTMLElement | SVGElement>
  ) => {
    e.stopPropagation();
    const view = app.store.reduxStore.getState().ui.view;
    if (view.view !== "graph") {
      return;
    }
    app.ui.graphView.onMouseUp(getMouseOpts(svgRef, e), { t: "background" });
    setDraggingTask(undefined);
  };

  const onWheel = (e: React.WheelEvent<HTMLElement>) => {
    e.preventDefault();
    app.ui.graphView.onWheel({ x: e.deltaX, y: e.deltaY });
  };

  const taskArr = Array.from(tasks);

  if (taskArr.length === 0) {
    connectors.push(<AddFirstTaskPrompt pos={Vec2.mul(view_size, 0.5)} />);
  }

  const nodes = taskArr.map(id => {
    const viewPos = toViewCoords(
      expect(app.ui.graphView.getTaskPosition(graphViewId, id))
    );
    const task = expect(app.domain.tasks.findById(id));
    const target: GraphMouseTarget = {
      t: "task",
      taskId: task.id,
    };
    const onTaskMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      app.ui.graphView.onMouseDown(getMouseOpts(svgRef, e), target);
    };
    const onTaskMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      app.ui.graphView.onMouseMove(getMouseOpts(svgRef, e), target);
    };
    const onTaskMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      app.ui.graphView.onMouseUp(getMouseOpts(svgRef, e), target);
    };

    const offsetPos =
      draggingTask != null && draggingTask.id === id
        ? {
            x: viewPos.x + (draggingTask.end.x - draggingTask.start.x),
            y: viewPos.y + (draggingTask.end.y - draggingTask.start.y),
          }
        : viewPos;

    const isSelected = selection.length > 0 && selection.includes(id);
    let hasDependants = false;
    task.dependencies.forEach(depId => {
      const dep = app.store.domain.dependencies.items.get(depId);
      if (dep && dep.left === task.id) {
        hasDependants = true;
      }
    });

    return (
      <div
        className="posAbsolute"
        style={{ left: offsetPos.x, top: offsetPos.y, width: TASK_WIDTH }}
        onMouseDown={onTaskMouseDown}
        onMouseMove={onTaskMouseMove}
        onMouseUp={onTaskMouseUp}
        onWheel={onWheel}
        key={task.id}
      >
        <TaskSmall
          cursor={activeTool === Tool.MOVE ? "grab" : undefined}
          isSelected={isSelected}
          task={task}
          isUnblocked={app.store.domain.tasks.unblocked.has(task.id)}
          isGoal={!hasDependants}
        />
      </div>
    );
  });

  /**
   * Get task position in view relative coords
   */
  const getTaskPosition = (taskId: TaskId) => {
    return toViewCoords(
      expect(app.ui.graphView.getTaskPosition(graphViewId, taskId))
    );
  };

  if (state.t === "new_dependency_adding" && state.connectTo.t !== "none") {
    let connectToPosition =
      state.connectTo.t === "task"
        ? getTaskPosition(state.connectTo.task)
        : state.connectTo.position;
    if (state.connectTo.t === "task" && state.existingTaskAnchor === "in") {
      connectToPosition = Vec2.add(connectToPosition, {
        x: TASK_WIDTH + 36,
        y: 0,
      });
    }
    const from =
      state.existingTaskAnchor === "out"
        ? Vec2.add(getTaskPosition(state.existingTask), {
            x: TASK_WIDTH + 36,
            y: 0,
          })
        : connectToPosition;
    const to =
      state.existingTaskAnchor === "in"
        ? Vec2.add(getTaskPosition(state.existingTask), { x: -21, y: 0 })
        : connectToPosition;

    connectors.push(<Connector from={from} to={to} />);
  }

  for (const taskId of taskArr) {
    const taskPos = expect(
      app.ui.graphView.getTaskPosition(graphViewId, taskId)
    );
    const viewPos = toViewCoords(taskPos);
    const task = expect(app.domain.tasks.findById(taskId));
    let hiddenDependencies = 0;
    let hiddenDependants = 0;
    for (const depId of task.dependencies) {
      if (renderedDeps.has(depId)) {
        continue;
      }
      const dep = expect(app.store.domain.dependencies.items.get(depId));

      let from = app.ui.graphView.getTaskPosition(graphViewId, dep.left);
      if (from != null)
        from = toViewCoords(Vec2.add(from, { x: TASK_WIDTH + 36, y: 0 }));

      let to = app.ui.graphView.getTaskPosition(graphViewId, dep.right);
      if (to != null) to = toViewCoords(Vec2.add(to, { x: -18, y: 0 }));

      let isSelected = false;
      if (selection.length > 0) {
        if (selection.includes(dep.left) || selection.includes(dep.right)) {
          isSelected = true;
        }
      }

      if (from == null && to != null) {
        hiddenDependencies += 1;
      } else if (to == null && from != null) {
        hiddenDependants += 1;
      } else if (to != null && from != null) {
        connectors.push(
          <Connector
            from={from}
            to={to}
            style={isSelected ? "selected" : "normal"}
          />
        );
      } else {
        throw new Error("Expect `from` or `to` to be defined");
      }
      renderedDeps.add(depId);
    }
    const onAnchorMouseDown = (anchor: TaskAnchor.T) => (
      e: React.MouseEvent<unknown>
    ) => {
      e.preventDefault();
      app.ui.graphView.onMouseDown(getMouseOpts(svgRef, e), {
        t: "task_anchor",
        taskId,
        anchor,
      });
    };
    const onAnchorMouseMove = (anchor: TaskAnchor.T) => (
      e: React.MouseEvent<unknown>
    ) => {
      e.preventDefault();
      e.stopPropagation();
      app.ui.graphView.onMouseMove(getMouseOpts(svgRef, e), {
        t: "task_anchor",
        taskId,
        anchor,
      });
    };
    const onAnchorMouseUp = (anchor: TaskAnchor.T) => (
      e: React.MouseEvent<unknown>
    ) => {
      e.preventDefault();
      app.ui.graphView.onMouseUp(getMouseOpts(svgRef, e), {
        t: "task_anchor",
        taskId,
        anchor,
      });
    };
    eventTargets.push(
      <EventRegion
        key={taskId + "in"}
        left={viewPos.x - 34}
        top={viewPos.y}
        width={36}
        height={36}
        onMouseDown={onAnchorMouseDown("in")}
        onMouseMove={onAnchorMouseMove("in")}
        onMouseUp={onAnchorMouseUp("in")}
        onWheel={onWheel}
      />
    );
    eventTargets.push(
      <EventRegion
        key={taskId + "out"}
        left={viewPos.x + TASK_WIDTH}
        top={viewPos.y}
        width={36}
        height={36}
        onMouseDown={onAnchorMouseDown("out")}
        onMouseMove={onAnchorMouseMove("out")}
        onMouseUp={onAnchorMouseUp("out")}
        onWheel={onWheel}
      />
    );
    const depAnchorInActive =
      state.t === "new_dependency_adding" &&
      state.existingTaskAnchor === "in" &&
      state.existingTask === taskId;
    const depAnchorOutActive =
      state.t === "new_dependency_adding" &&
      state.existingTaskAnchor === "out" &&
      state.existingTask === taskId;
    connectors.push(
      <DependencyAnchor
        anchor="in"
        state={depAnchorInActive ? "selected" : "none"}
        pos={Vec2.add(viewPos, { x: -28, y: 1 })}
      />
    );
    connectors.push(
      <DependencyAnchor
        anchor="out"
        state={depAnchorOutActive ? "selected" : "none"}
        pos={Vec2.add(viewPos, { x: TASK_WIDTH, y: 1 })}
      />
    );
    if (hiddenDependencies > 0) {
      connectors.push(
        <HiddenDepIndicator
          pos={Vec2.add(viewPos, { x: -35, y: 0 })}
          count={hiddenDependencies}
        />
      );
    }
    if (hiddenDependants > 0) {
      connectors.push(
        <HiddenDepIndicator
          pos={Vec2.add(viewPos, { x: TASK_WIDTH + 5, y: 0 })}
          count={hiddenDependants}
        />
      );
    }

    console.log(state.t);
    if (state.t === "existing_dependency_adding" && state.taskId === taskId) {
      const deps = app.domain.getDependenciesForTask(state.taskId);
      let tasks: Task[];
      let offset: number;
      const isHiddenFromGraph = (task: Task) =>
        !graphView.taskPositions.has(task.id);
      if (state.anchor === "in") {
        tasks = app.domain.tasks
          .findByIds(deps.dependsOn)
          .filter(isHiddenFromGraph);
        offset = -(TASK_WIDTH + 64);
      } else {
        tasks = app.domain.tasks
          .findByIds(deps.dependedOnBy)
          .filter(isHiddenFromGraph);
        offset = TASK_WIDTH + 64;
      }
      const onChooseTask = (taskId: TaskId) => {
        app.saveAction(
          graphViewTaskAdd(graphViewId, taskId, {
            x: taskPos.x + offset,
            y: taskPos.y,
          })
        );
        app.ui.graphView.resetState();
      };
      nodes.push(
        <div
          className="posAbsolute"
          style={{
            width: TASK_WIDTH,
            left: viewPos.x + offset,
            top: viewPos.y,
          }}
        >
          <TaskList
            onChooseTask={onChooseTask}
            tasks={tasks}
            unblocked={app.store.domain.tasks.unblocked}
          />
        </div>
      );
    }
  }

  let svgOverlays: React.ReactNode[] = [];

  if (state.t === "selection_marquee") {
    svgOverlays.push(<SelectionMarquee start={state.start} end={state.end} />);
  }

  if (state.t === "task_adding") {
    if (state.connectTo.t === "task") {
      const existingTaskPos = getTaskPosition(state.connectTo.taskId);
      let from =
        state.connectTo.anchor === "out"
          ? Vec2.add(existingTaskPos, { x: TASK_WIDTH + 36, y: 0 })
          : Vec2.add(state.position, { x: TASK_WIDTH, y: 0 });
      let to =
        state.connectTo.anchor === "in"
          ? Vec2.add(existingTaskPos, { x: -20, y: 0 })
          : state.position;
      connectors.push(<Connector style="pending" from={from} to={to} />);
    }

    let top = state.position.y;
    let maxHeight = view_size.y - state.position.y;
    if (maxHeight < MIN_LOOKUP_HEIGHT) {
      maxHeight = MIN_LOOKUP_HEIGHT;
      top = view_size.y - MIN_LOOKUP_HEIGHT;
    }
    nodes.push(
      <div
        className="posAbsolute"
        style={{
          left: state.position.x,
          top,
        }}
      >
        <TaskLookup
          finderStore={state.finder}
          domainActions={app.domain}
          maxHeight={maxHeight}
          onCreateTask={app.ui.graphView.onCreateTask}
          onSelectTask={app.ui.graphView.onSelectTask}
          autoFocus={true}
        />
      </div>
    );
  }

  return (
    <Measurable
      className={classnames("posRelative fillDimensions bg-col-bg-b", {})}
      onResize={onMeasurableResize}
    >
      <svg
        ref={svgRef}
        className="posAbsolute fillDimensions"
        width={view_size.x}
        height={view_size.y}
      >
        {connectors}
      </svg>
      <Overlay
        viewSize={view_size}
        onMouseDown={onBackgroundMouseDown}
        onMouseUp={onBackgroundMouseUp}
        onMouseMove={onBackgroundMouseMove}
        onMouseLeave={onBackgroundMouseUp}
        className={classnames({
          [styles.moveTool]: activeTool === Tool.MOVE,
        })}
        onWheel={onWheel}
      >
        {nodes}
        {eventTargets}
      </Overlay>
      <svg
        className="posAbsolute fillDimensions pointer-events-none"
        width={view_size.x}
        height={view_size.y}
      >
        {svgOverlays}
      </svg>
    </Measurable>
  );
});

const getRelativeClientCoords = (
  el: React.MutableRefObject<SVGSVGElement | null>,
  e: React.MouseEvent<unknown>
) => {
  const bounds = expect(el.current).getBoundingClientRect();

  return {
    x: e.clientX - bounds.left,
    y: e.clientY - bounds.top,
  };
};

const getMouseOpts = (
  svgRef: React.MutableRefObject<SVGSVGElement | null>,
  e: React.MouseEvent<unknown>
): MouseOpts => {
  return {
    cursorPos: getRelativeClientCoords(svgRef, e),
    cursorDelta: { x: e.movementX, y: e.movementY },
    metaKey: e.metaKey,
    button: e.button,
  };
};
