import * as React from "react";
import { TaskId } from "../persistence/log/identifiers";
import { observer } from "mobx-react-lite";
import { FuzzyFinderStore, SearchResult } from "../state/data/ui/fuzzy_finder";
import { FuzzyFinder } from "./ui/FuzzyFinder";
import { DomainActions } from "../state/domain";

type Props = {
  maxHeight?: number;
  finderStore: FuzzyFinderStore<TaskId | "__create">;
  onSelectTask?: (t: TaskId) => void;
  onCreateTask?: (description: string) => void;
  domainActions: DomainActions;
  autoFocus?: boolean;
};

const fuzzyScore = (
  query: string,
  candidate: string,
  key: TaskId
): SearchResult<TaskId> => {
  let queryPos = 0;
  let candidatePos = 0;
  let consecutiveMatches = "";
  let consecutiveNoMatches = "";
  let result: SearchResult<TaskId> = {
    key,
    score: 0,
    matchOutput: [],
  };

  let lcQuery = query.toLocaleLowerCase();
  let lcCandidate = candidate.toLocaleLowerCase();

  const resetMatches = () => {
    if (consecutiveMatches.length > 0) {
      result.matchOutput.push({ t: "match", text: consecutiveMatches });
      consecutiveMatches = "";
    }
  };
  const resetNonMatches = () => {
    if (consecutiveNoMatches.length > 0) {
      result.matchOutput.push({ t: "no-match", text: consecutiveNoMatches });
      consecutiveNoMatches = "";
    }
  };

  while (true) {
    if (queryPos < lcQuery.length && candidatePos >= lcCandidate.length) {
      // End of candidate without finishing the query, no score for you
      return { key, score: 0, matchOutput: [] };
    }
    if (queryPos >= lcQuery.length) {
      resetMatches();
      resetNonMatches();
      if (candidatePos < lcCandidate.length) {
        result.matchOutput.push({
          t: "no-match",
          text: candidate.substr(candidatePos),
        });
      }
      return result;
    }
    if (lcCandidate[candidatePos] === lcQuery[queryPos]) {
      resetNonMatches();

      // Match
      consecutiveMatches += candidate[candidatePos];
      result.score += consecutiveMatches.length * 2;
      queryPos += 1;
      candidatePos += 1;

      // Positional bonus (earlier occurrences are better)
      result.score += Math.max(0, 1 - candidatePos / 10);
    } else {
      resetMatches();
      consecutiveNoMatches += candidate[candidatePos];
      candidatePos += 1;
    }
  }
};

export const TaskLookup = observer((props: Props) => {
  const onSelect = (taskId: TaskId | "__create", query: string) => {
    if (taskId === "__create") {
      props.onCreateTask && props.onCreateTask(query.trim());
    } else {
      props.onSelectTask && props.onSelectTask(taskId);
    }
  };

  const canCreate = props.onCreateTask != null;
  const canLookup = props.onSelectTask != null;

  let placeholderText = "Search or create tasks";
  if (!canLookup) {
    placeholderText = "Create task";
  }
  if (!canCreate) {
    placeholderText = "Search tasks";
  }

  const searchFunc = (query: string) => {
    console.log("-----");
    const results: SearchResult<TaskId | "__create">[] = [];
    if (canCreate) {
      results.push({
        key: "__create",
        score: 99999999,
        matchOutput: [
          { t: "no-match", text: `Create task "` },
          { t: "match", text: query },
          { t: "no-match", text: `"` },
        ],
      });
    }

    if (canLookup) {
      for (const [taskId, task] of props.domainActions.tasks.store.tasks
        .items) {
        const result = fuzzyScore(query, task.title.get(), taskId);
        if (result.score > 0) {
          results.push(result);
        }
      }
    }
    return results;
  };

  return (
    <FuzzyFinder
      maxHeight={props.maxHeight}
      placeholderText={placeholderText}
      store={props.finderStore}
      searchFunc={searchFunc}
      onSelect={onSelect}
      autoFocus={props.autoFocus}
    />
  );
});
