import { RootAction } from "~store/actions";
import { ActionType } from "~store/action-types";
import { UiModalActions } from "./actions";

export namespace UiModalStore {
  export type State = {
    currentModal: undefined | UiModalActions.Modal;
  };
  export const initialState = (): State => ({
    currentModal: undefined,
  });

  export const reducer = (
    state: State = initialState(),
    action: RootAction
  ) => {
    switch (action.type) {
      case ActionType.UI_MODAL_SHOW:
        return { ...state, currentModal: action.modal };
      case ActionType.UI_MODAL_HIDE:
        return { ...state, currentModal: undefined };
    }
    return state;
  };
}
