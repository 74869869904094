import * as React from "react";
import { stylesheet } from "typestyle";
import { Theme } from "~themes/theme";
import { withStyles } from "~themes/provider";

const styles = (theme: Theme) =>
  stylesheet({
    modalContainer: {
      display: "flex",
      backgroundColor: `rgba(0, 0, 0, 0.7)`,
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100vh",
      pointerEvents: "all",
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      border: `1px solid ${theme.accentA}`,
      background: theme.backgroundB,
      maxHeight: "600px",
      overflowY: "auto",
      color: "white",
      $nest: {
        "@media screen and (max-height: 800px)": {
          maxHeight: "100vh",
        },
      },
    },
  });

type Props = {
  onClickOutside(): void;
  maxWidth?: number;
};

export const BaseModal = withStyles(styles)<Props>(props => {
  const onBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget == e.target) {
      props.onClickOutside();
    }
  };

  return (
    <div
      className={props.styles.modalContainer}
      onMouseDown={onBackgroundClick}
    >
      <div className={props.styles.modal} style={{ maxWidth: props.maxWidth }}>
        {props.children}
      </div>
    </div>
  );
});
