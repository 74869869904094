import { action, observable, ObservableMap } from "mobx";
import { GraphViewId, TaskId } from "../../../persistence/log/identifiers";
import { expect } from "../../../util";
import { Vec2 } from "../../../base/vec2";
import { DomainStore } from "../../data/domain";
import { ActionErrorTag, ActionResult } from "../../../persistence/log/errors";
import { Result } from "../../../base/result";

export class GraphViewActions {
  store: DomainStore;

  constructor(store: DomainStore) {
    this.store = store;
  }

  create = action((id: GraphViewId, label: string) => {
    this.store.graphViews.items.set(id, {
      graphViewId: id,
      taskPositions: new ObservableMap(),
      label: observable.box(label),
    });
  });

  addTask = action(
    (
      graphViewId: GraphViewId,
      taskId: TaskId,
      position: Vec2.T
    ): ActionResult => {
      const graphView = expect(
        this.store.graphViews.items.get(graphViewId),
        "Graph view not found"
      );
      if (this.store.tasks.items.get(taskId) == null) {
        return Result.err(ActionErrorTag.TASK_NOT_FOUND);
      }
      graphView.taskPositions.set(taskId, { ...position });
      return Result.ok(undefined);
    }
  );

  remove = action(
    (id: GraphViewId): ActionResult => {
      this.store.graphViews.items.delete(id);
      return Result.ok(undefined);
    }
  );

  moveTask = action(
    (graphViewId: GraphViewId, taskId: TaskId, deltaPosition: Vec2.T) => {
      const graphView = expect(
        this.store.graphViews.items.get(graphViewId),
        "Graph view not found"
      );
      const currentTask = expect(graphView.taskPositions.get(taskId));
      const newPosition = {
        x: currentTask.x + deltaPosition.x,
        y: currentTask.y + deltaPosition.y,
      };

      graphView.taskPositions.set(taskId, newPosition);
    }
  );

  hideTasks = action((graphViewId: GraphViewId, taskIds: TaskId[]) => {
    const graphView = expect(
      this.store.graphViews.items.get(graphViewId),
      "Graph view not found"
    );
    taskIds.forEach(id => {
      graphView.taskPositions.delete(id);
    });
  });

  updateLabel = action((id: GraphViewId, label: string) => {
    const graphView = expect(this.store.graphViews.items.get(id));
    graphView.label.set(label);
  });
}
