import { UiStore } from "./ui/store";
import { RootAction } from "./actions";

export type State = {
  ui: UiStore.State;
};

export const initialState = (): State => ({
  ui: UiStore.initialState(),
});

export const rootReducer = (
  state: State = initialState(),
  action: RootAction
): State => {
  console.log("ACTION", action.type);

  if (action == null) {
    return state;
  }

  return { ...state, ui: UiStore.reducer(state.ui, action) };
};
