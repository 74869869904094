export namespace Result {
  type Ok<Value> = { t: "ok"; v: Value };
  type Error<Err> = { t: "err"; e: Err };
  export type T<Value, Err> = Ok<Value> | Error<Err>;

  export const ok = <A, B>(v: A): T<A, B> => ({ t: "ok", v });
  export const err = <A, B>(e: B): T<A, B> => ({ t: "err", e });

  export const isOk = <A, B>(v: T<A, B>): v is Ok<A> => {
    return v.t === "ok";
  };
  export const isErr = <A, B>(v: T<A, B>): v is Error<B> => {
    return v.t === "err";
  };
}
