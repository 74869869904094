import * as React from "react";
import { gridBaseline } from "~base/styles";
import { Heading1, Text } from "~base/typography";
import { Stack } from "~view/layout/Stack";
import { PlainButton } from "~view/ui/button/Button";
import { FocusBox } from "~view/layout/FocusBox";
import { TextInput } from "~view/ui/form/TextInput";
import { useDispatch, useSelector } from "~store/store";
import { UiFileActions } from "~store/ui/file/actions";
import { State } from "~store/reducer";
import { Alert } from "~view/ui/Alert";
import { UiStore } from "~store/ui/store";

const getWaitlistState = (state: State) => state.ui.file.waitlistSignupState;

export const WaitlistSignup = () => {
  const dispatch = useDispatch();
  const waitlistState = useSelector(getWaitlistState);
  const [emailAddress, setEmailAddress] = React.useState("");
  const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(UiFileActions.waitlistSignupRequest({ emailAddress }));
  };

  if (waitlistState.t === "successful") {
    return <SignupComplete emailAddress={waitlistState.emailAddress} />;
  }

  return (
    <FocusBox>
      <form onSubmit={onSubmitForm}>
        <Stack direction="column" spacing={gridBaseline * 2}>
          {waitlistState.t === "error" && (
            <Alert>{waitlistState.message}</Alert>
          )}
          <Heading1>Join the waitlist</Heading1>
          <Text level="p">
            Cloud sync will be a paid upgrade, but it isn't ready just yet. If
            it's something you're interested in, let me know by joining the
            waitlist and you'll be one of the first to get access.
          </Text>
          <Stack direction="column">
            <div style={{ display: "flex" }}>
              <Text level="h5">
                <label htmlFor="waitlist__email-address">
                  Your email address
                </label>
              </Text>
            </div>
            <div style={{ display: "flex" }}>
              <TextInput
                id="waitlist__email-address"
                value={emailAddress}
                setValue={setEmailAddress}
                placeholder="email@address.com"
              />
            </div>
          </Stack>
          <Text level="h2">Privacy Info</Text>
          <Text level="p">
            If you submit your email here, we'll log the following data:
          </Text>
          <Text level="p">
            <ul>
              <li>Your email address</li>
              <li>The time at which you submitted your email address</li>
              <li>Your public IP address (used only to track abuse)</li>
            </ul>
          </Text>
          <Text level="p">
            I'll only send you a single email once your invite is ready, and
            won't hassle you any more if you decide not to sign up.
          </Text>
          <Stack direction="row" justify="center">
            <PlainButton type="submit" disabled={waitlistState.t === "pending"}>
              {waitlistState.t === "pending"
                ? "Sending..."
                : "Join the waitlist"}
            </PlainButton>
          </Stack>
        </Stack>
      </form>
    </FocusBox>
  );
};

export const SignupComplete = (props: { emailAddress: string }) => {
  const dispatch = useDispatch();
  const onClickBack = () => dispatch(UiFileActions.waitlistSignupReset({}));
  const onClickDone = () => dispatch(UiStore.pathSet({ path: "/save" }));

  return (
    <FocusBox>
      <Stack direction="column" spacing={gridBaseline * 2}>
        <Heading1>You're on the list</Heading1>
        <Text level="p">
          <p>
            Thanks for letting me know, I'll email you at{" "}
            <strong>{props.emailAddress}</strong> when cloud sync is available.
          </p>
        </Text>

        <Stack direction="row" justify="center">
          <PlainButton onClick={onClickBack}>Oops, go back</PlainButton>
          <PlainButton onClick={onClickDone}>Done</PlainButton>
        </Stack>
      </Stack>
    </FocusBox>
  );
};
