import * as React from "react";
import { stylesheet } from "typestyle";
import { Text } from "~base/typography";
import { withStyles } from "~themes/provider";
import { Theme } from "~themes/theme";
import { gridBaseline } from "~base/styles";

const styleMapper = (theme: Theme) =>
  stylesheet({
    alert: {
      borderWidth: 1,
      borderRadius: 1,
      background: theme.backgroundF,
      borderColor: theme.accentA,
      borderStyle: "solid",
      padding: `${gridBaseline}px ${gridBaseline * 2}px`,
    },
  });

export const Alert: React.FunctionComponent = withStyles(styleMapper)(
  ({ children, styles }) => (
    <div className={styles.alert} role="alert">
      <Text level="p">{children}</Text>
    </div>
  )
);
