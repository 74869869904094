import { IObservableArray, IObservableValue, observable } from "mobx";
import { GraphViewStore } from "./graph_view";
import { GraphViewId, TaskId } from "../../../persistence/log/identifiers";
import { SidebarStore } from "./sidebar";

type ContextMenuContent = { t: "graph_view"; graphViewId: GraphViewId };

export type UiState =
  | { t: "none" }
  | { t: "view_name_editing"; graphViewId: GraphViewId; value: string }
  | {
      t: "context_menu";
      top: number;
      left: number;
      content: ContextMenuContent;
    };

export type SidePanelState =
  | { t: "collapsed" }
  | { t: "menu" }
  | { t: "selection" };

export type Notification = {
  id: string;
  text: string;
};

export class UiStore {
  path: IObservableValue<string>;
  state: IObservableValue<UiState>;
  selection: IObservableArray<TaskId>;
  graphView: GraphViewStore;
  sidebar: SidebarStore;
  sidePanel: IObservableValue<SidePanelState>;
  notifications: IObservableArray<Notification>;

  constructor() {
    this.path = observable.box("/");
    this.state = observable.box({ t: "none" });
    this.notifications = observable.array([]);
    this.selection = observable.array([]);
    this.graphView = new GraphViewStore();
    this.sidebar = new SidebarStore();
    this.sidePanel = observable.box({ t: "menu" });
  }
}
