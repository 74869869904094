import { IObservableArray, IObservableValue, observable } from "mobx";

export type FuzzyPart =
  | { t: "match"; text: string }
  | { t: "no-match"; text: string };

export type SearchResult<K> = {
  key: K;
  score: number;
  matchOutput: FuzzyPart[];
};

export class FuzzyFinderStore<K> {
  query: IObservableValue<string> = observable.box("");
  selectedIndex: IObservableValue<number> = observable.box(0);
  options: IObservableArray<SearchResult<K>> = observable.array();
}
