import * as React from "react";
import { stylesheet } from "typestyle";
import classNames from "classnames";
import { gridBaseline } from "../../base/styles";

const styles = stylesheet({
  stack: {
    display: "flex",
  },
});

type Props = {
  className?: string;
  children: React.ReactNodeArray | React.ReactNode;
  direction: "row" | "column";
  scroll?: boolean;
  spacing?: number;
  align?: "center" | "flex-start" | "flex-end";
  justify?: "center" | "flex-start" | "flex-end";
};

export const Stack: React.FunctionComponent<Props> = props => {
  const paddingAttr = props.direction === "row" ? "paddingLeft" : "paddingTop";
  const overflowAttr = props.direction === "row" ? "overflowX" : "overflowY";
  const overflowVal = props.scroll ? "auto" : "hidden";
  const spacing = props.spacing != null ? props.spacing : gridBaseline;

  let items = Array.isArray(props.children) ? props.children : [props.children];

  return (
    <div
      className={classNames(styles.stack, props.className)}
      style={{
        flexDirection: props.direction,
        [overflowAttr]: overflowVal,
        alignItems: props.align,
        justifyContent: props.justify,
      }}
    >
      {items
        .filter(item => item != null)
        .map((item, i) => (
          <div key={i} style={{ [paddingAttr]: i > 0 ? spacing : 0 }}>
            {item}
          </div>
        ))}
    </div>
  );
};
