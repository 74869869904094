export const enum ActionType {
  UI_PATH_SET = "ui/path_set",
  UI_PATH_CHANGED = "ui/path_changed",
  UI_PROJECT_LOADED = "ui/project_loaded",
  UI_MODAL_SHOW = "ui/modal/show",
  UI_MODAL_HIDE = "ui/modal/hide",
  UI_MODAL_OPEN_PROJECT_CONFIRM = "ui/modal/open_project/confirm",
  UI_FILE_WAITLIST_SIGNUP_RESET = "ui/file/waitlist_signup_reset",
  UI_FILE_WAITLIST_SIGNUP_REQUEST = "ui/file/waitlist_signup_request",
  UI_FILE_WAITLIST_SIGNUP_SUCCESS = "ui/file/waitlist_signup_success",
  UI_FILE_WAITLIST_SIGNUP_ERROR = "ui/file/waitlist_signup_error",
  PROJECT_OPEN_FROM_FILE = "project/open_from_file",
  PROJECT_LOAD = "project/load",
}
