import { action, runInAction } from "mobx";
import { Vec2 } from "../../../base/vec2";
import { GraphViewId, TaskId } from "../../../persistence/log/identifiers";
import {
  createTask,
  createTaskDependency,
  graphViewTaskAdd,
  graphViewTaskMove,
} from "../../../persistence/log/actions";
import { AppStore } from "../../data";
import { BUTTON, GraphMouseTarget, Tool } from "../../data/ui/graph_view";
import { DomainActions } from "../../domain";
import {
  CLICK_DISTANCE_TOLERANCE,
  CLICK_TIMEOUT,
  TASK_WIDTH,
} from "../../../config/sizes";
import { expect } from "../../../util";
import { ActionResult } from "../../../persistence/log/errors";
import { DomainAction } from "../../../persistence/log/action_definitions";
import { FuzzyFinderStore } from "../../data/ui/fuzzy_finder";
import { SfxActions } from "~state";
import { SfxState } from "~sfx/sfx";

export type MouseOpts = {
  /**
   * Cursor position relative to the top left of the graph view (not camera coords)
   */
  cursorPos: Vec2.T;
  cursorDelta: Vec2.T;
  metaKey: boolean;
  button: BUTTON;
};

export class GraphViewActions {
  store: AppStore;
  domainActions: DomainActions;
  saveAction: (action: DomainAction) => ActionResult;
  sfxActions: SfxActions;

  constructor(
    store: AppStore,
    domainActions: DomainActions,
    saveAction: (action: DomainAction) => ActionResult,
    sfxActions: SfxActions
  ) {
    this.store = store;
    this.domainActions = domainActions;
    this.saveAction = saveAction;
    this.sfxActions = sfxActions;
  }

  isTaskSelected = (taskId: TaskId) => {
    return this.store.ui.selection.includes(taskId);
  };

  getTaskPosition = (graphViewId: GraphViewId, taskId: TaskId) => {
    const graphView = this.store.domain.graphViews.items.get(graphViewId);
    if (graphView == null) return undefined;
    const taskPosition = graphView.taskPositions.get(taskId);
    if (taskPosition == null) return undefined;

    const viewState = this.store.ui.graphView.state.get();
    const selection = this.store.ui.selection;
    if (viewState.t === "selection_moving") {
      if (selection.length > 0) {
        if (selection.includes(taskId)) {
          const offset = Vec2.subtract(viewState.end, viewState.start);
          return Vec2.add(taskPosition, offset);
        }
      }
    }

    return taskPosition;
  };

  onMouseDown = action((mouseOpts: MouseOpts, target: GraphMouseTarget) => {
    const store = this.store.ui.graphView;
    const selection = this.store.ui.selection;

    this.store.ui.graphView.mouseState = {
      t: "down",
      start: mouseOpts.cursorPos,
      time: performance.now(),
      target,
    };

    if (this.store.ui.graphView.activeTool.get() === Tool.MOVE) {
      this.store.ui.graphView.state.set({ t: "camera_moving" });
      return;
    }

    if (target.t === "task" && selection.length > 0 && mouseOpts.metaKey) {
      if (selection.includes(target.taskId)) {
        // Deselect task
        this.store.ui.selection.replace(
          selection.filter(taskId => taskId !== target.taskId)
        );
      } else {
        // Select task
        this.store.ui.selection.replace([...selection, target.taskId]);
        this.store.ui.sidePanel.set({
          t: "selection",
        });
      }
      return;
    }

    if (
      (mouseOpts.button === BUTTON.LEFT && mouseOpts.metaKey) ||
      mouseOpts.button === BUTTON.MIDDLE
    ) {
      this.store.ui.graphView.state.set({ t: "camera_moving" });
    }

    if (target.t === "task") {
      if (!this.isTaskSelected(target.taskId)) {
        // This task is not selected, reset the selection to just this task
        this.store.ui.selection.replace([target.taskId]);
        this.store.ui.sidePanel.set({
          t: "selection",
        });
      }

      store.state.set({
        t: "selection_moving",
        start: mouseOpts.cursorPos,
        end: mouseOpts.cursorPos,
      });
      return;
    }

    if (target.t === "task_anchor") {
      store.state.set({
        t: "new_dependency_adding",
        existingTask: target.taskId,
        existingTaskAnchor: target.anchor,
        connectTo: { t: "task", task: target.taskId },
      });
    }
  });

  resetState = action(() => {
    this.store.ui.graphView.state.set({ t: "none" });
  });

  resetSelection = action(() => {
    this.store.ui.selection.clear();
  });

  onCreateTask = (title: string) => {
    const state = this.store.ui.graphView.state.get();
    const view = this.store.reduxStore.getState().ui.view;
    if (view.view !== "graph" || state.t !== "task_adding") {
      return;
    }

    const newTask = createTask(title);
    this.saveAction(newTask);

    let result = this.saveAction(
      graphViewTaskAdd(
        view.graphViewId,
        newTask.id,
        this.toGraphCoords(state.position)
      )
    );
    if (result.t === "ok") {
      this.sfxActions.play(SfxState.Sound.SELECT);
    }

    this.store.ui.selection.replace([newTask.id]);
    this.store.ui.sidePanel.set({
      t: "selection",
    });

    if (state.connectTo.t === "task") {
      if (state.connectTo.anchor === "out") {
        this.saveAction(
          createTaskDependency(newTask.id, state.connectTo.taskId)
        );
      }
      if (state.connectTo.anchor === "in") {
        this.saveAction(
          createTaskDependency(state.connectTo.taskId, newTask.id)
        );
      }
    }

    this.resetState();
  };

  onSelectTask = (taskId: TaskId) => {
    const state = this.store.ui.graphView.state.get();
    const view = this.store.reduxStore.getState().ui.view;
    if (view.view !== "graph" || state.t !== "task_adding") {
      return;
    }

    this.saveAction(
      graphViewTaskAdd(
        view.graphViewId,
        taskId,
        this.toGraphCoords(state.position)
      )
    );

    if (state.connectTo.t === "task") {
      if (state.connectTo.anchor === "out") {
        this.saveAction(createTaskDependency(taskId, state.connectTo.taskId));
      }
    }

    this.resetState();
  };

  onWheel = (movement: Vec2.T) => {
    this.store.ui.graphView.cameraOrigin.set(
      Vec2.subtract(this.store.ui.graphView.cameraOrigin.get(), movement)
    );
  };

  onMouseUp = (mouseOpts: MouseOpts, target: GraphMouseTarget) => {
    const prevMouseState = this.store.ui.graphView.mouseState;
    this.store.ui.graphView.mouseState = { t: "none" };

    const state = this.store.ui.graphView.state.get();
    const selection = this.store.ui.selection;
    const view = this.store.reduxStore.getState().ui.view;
    if (view.view !== "graph") {
      return;
    }

    const prevTarget =
      prevMouseState.t === "down" ? prevMouseState.target : undefined;
    const wasClick =
      prevMouseState.t === "down" &&
      performance.now() - prevMouseState.time < CLICK_TIMEOUT &&
      Vec2.dist(prevMouseState.start, mouseOpts.cursorPos) <=
        CLICK_DISTANCE_TOLERANCE;

    if (
      state.t === "none" &&
      wasClick &&
      prevTarget &&
      prevTarget.t === "background"
    ) {
      if (target.t === "background") {
        // If anything is selected, deselect first
        if (selection.length > 0) {
          this.resetSelection();
        } else {
          this.store.ui.graphView.state.set({
            t: "task_adding",
            position: mouseOpts.cursorPos,
            finder: new FuzzyFinderStore<TaskId>(),
            connectTo: { t: "none" },
          });
        }
      }

      return;
    }

    if (wasClick && prevTarget?.t === "task_anchor") {
      this.store.ui.graphView.state.set({
        t: "existing_dependency_adding",
        taskId: prevTarget.taskId,
        anchor: prevTarget.anchor,
      });
      return;
    }

    if (state.t === "new_dependency_adding" && state.connectTo.t === "space") {
      this.resetSelection();
      this.store.ui.graphView.state.set({
        t: "task_adding",
        finder: new FuzzyFinderStore<TaskId>(),
        position:
          state.existingTaskAnchor === "out"
            ? mouseOpts.cursorPos
            : Vec2.subtract(mouseOpts.cursorPos, { x: TASK_WIDTH, y: 0 }),
        connectTo: {
          t: "task",
          taskId: state.existingTask,
          anchor: state.existingTaskAnchor,
        },
      });
      return;
    }

    if (state.t === "new_dependency_adding" && state.connectTo.t === "task") {
      let result;
      if (state.existingTaskAnchor === "in") {
        result = this.saveAction(
          createTaskDependency(state.existingTask, state.connectTo.task)
        );
      } else {
        result = this.saveAction(
          createTaskDependency(state.connectTo.task, state.existingTask)
        );
      }
      if (result.t === "ok") {
        this.sfxActions.play(SfxState.Sound.SELECT);
      }
    }

    if (state.t === "camera_moving") {
      this.store.ui.graphView.state.set({ t: "none" });
    } else if (state.t === "selection_moving") {
      if (selection.length > 0) {
        // Apply task selection move
        this.saveAction(
          graphViewTaskMove(view.graphViewId, selection, {
            x: state.end.x - state.start.x,
            y: state.end.y - state.start.y,
          })
        );
      }

      this.store.ui.graphView.state.set({ t: "none" });
    } else {
      // Clear any other states
      this.store.ui.graphView.state.set({ t: "none" });
    }
  };

  toViewCoords = (graphCoords: Vec2.T): Vec2.T =>
    Vec2.add(
      Vec2.add(
        this.store.ui.graphView.cameraOrigin.get(),
        Vec2.mul(this.store.ui.graphView.viewSize.get(), 0.5)
      ),
      graphCoords
    );

  toGraphCoords = (viewCoords: Vec2.T): Vec2.T =>
    Vec2.subtract(
      viewCoords,
      Vec2.add(
        this.store.ui.graphView.cameraOrigin.get(),
        Vec2.mul(this.store.ui.graphView.viewSize.get(), 0.5)
      )
    );

  selectTasksInRect = (start: Vec2.T, end: Vec2.T) => {
    const view = this.store.reduxStore.getState().ui.view;
    if (view.view !== "graph") {
      return;
    }
    const graphView = expect(
      this.store.domain.graphViews.items.get(view.graphViewId)
    );

    const { x: x1, y: y1 } = this.toGraphCoords(start);
    const { x: x2, y: y2 } = this.toGraphCoords(end);
    const selLeft = Math.min(x1, x2);
    const selTop = Math.min(y1, y2);
    const selRight = Math.max(x1, x2);
    const selBottom = Math.max(y1, y2);

    const selectedTasks: TaskId[] = [];

    for (let [id, { x: taskLeft, y: taskTop }] of graphView.taskPositions) {
      const taskRight = taskLeft + TASK_WIDTH;
      const taskBottom = taskTop + 50;

      const isInSelection = !(
        taskLeft > selRight ||
        taskRight < selLeft ||
        taskTop > selBottom ||
        taskBottom < selTop
      );
      if (isInSelection) {
        selectedTasks.push(id);
      }
    }

    runInAction(() => {
      this.store.ui.selection.replace(selectedTasks);
      this.store.ui.sidePanel.set({
        t: "selection",
      });
    });
  };

  updateViewSize = (newSize: Vec2.T) => {
    const currentSize = this.store.ui.graphView.viewSize.get();
    if (!Vec2.eq(currentSize, newSize)) {
      this.store.ui.graphView.viewSize.set(newSize);
    }
  };

  onChooseTool = action((tool: Tool) => {
    this.store.ui.graphView.activeTool.set(tool);
  });

  onMouseMove = (mouseOpts: MouseOpts, target: GraphMouseTarget) => {
    const state = this.store.ui.graphView.state.get();
    const mouseState = this.store.ui.graphView.mouseState;
    if (
      mouseState.t === "down" &&
      (state.t === "selection_marquee" ||
        (state.t === "none" &&
          Vec2.dist(mouseState.start, mouseOpts.cursorPos) >
            CLICK_DISTANCE_TOLERANCE))
    ) {
      const start = Vec2.clone(mouseState.start);
      const end = mouseOpts.cursorPos;
      this.store.ui.graphView.state.set({
        t: "selection_marquee",
        start,
        end,
      });
      this.selectTasksInRect(start, end);
    } else if (state.t === "camera_moving") {
      const cameraOrigin = this.store.ui.graphView.cameraOrigin.get();
      runInAction(() =>
        this.store.ui.graphView.cameraOrigin.set(
          Vec2.add(cameraOrigin, mouseOpts.cursorDelta)
        )
      );
    } else if (state.t === "selection_moving") {
      runInAction(() =>
        this.store.ui.graphView.state.set({
          ...state,
          end: mouseOpts.cursorPos,
        })
      );
    } else if (state.t === "new_dependency_adding") {
      runInAction(() => {
        this.store.ui.graphView.state.set({
          ...state,
          connectTo:
            target.t === "background"
              ? { t: "space", position: mouseOpts.cursorPos }
              : { t: "task", task: target.taskId },
        });
      });
    }
  };
}
