import { Vec2 } from "./vec2";

export type SvgCommand = string & { __svgCommand: never };

export const command = (command: string) => command as SvgCommand;
export const close = () => `Z` as SvgCommand;
export const move = (x: number, y: number) => `M ${x} ${y}` as SvgCommand;
export const line = (x: number, y: number) => `L ${x} ${y}` as SvgCommand;
export const curve = (end: Vec2.T, controlA: Vec2.T, controlB: Vec2.T) =>
  `C ${controlA.x} ${controlA.y}, ${controlB.x} ${controlB.y}, ${end.x} ${end.y}` as SvgCommand;

export const compose = (...commands: SvgCommand[]) => commands.join(" ");
