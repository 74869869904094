/*
   * GET requests could be something like this, creating a query string
  for (let key in Object.keys(req)) {
    encodeURIComponent(req[key]);
  }
  */

type CommonResponse<SpecificResponse, SpecificError> =
  | ({ t: "Success" } & SpecificResponse)
  | {
      t: "Error";
      user_message?: string;
      details: SpecificError | undefined;
    }
  | {
      t: "ServerError";
      status: number;
    };

export const apiCall = <Request, Response, SpecificError = never>(
  path: string,
  method: "POST" | "PUT" | "DELETE"
) => async (req: Request): Promise<CommonResponse<Response, SpecificError>> => {
  const response = fetch(path, {
    method,
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(req),
  });

  const r = await response;
  if (r.status !== 200) {
    return { t: "ServerError", status: r.status };
  }

  const body: CommonResponse<Response, SpecificError> = await r.json();
  return body;
};
