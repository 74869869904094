import { TaskStore } from "./tasks";
import { DomainAction } from "../../../persistence/log/action_definitions";
import { GraphViewStore } from "./graph_view";
import { TaskDependencyStore } from "./task_dependencies";
import * as mobx from "mobx";

export class DomainStore {
  tasks: TaskStore;
  dependencies: TaskDependencyStore;
  graphViews: GraphViewStore;
  eventLog: DomainAction[];

  /**
   * Is there any document open? If not, the domain will be empty and should not be saved.
   */
  isOpen: mobx.IObservableValue<boolean>;

  /**
   * Have any changes been made to the document since it was saved?
   */
  isDirty: mobx.IObservableValue<boolean>;

  constructor() {
    this.tasks = new TaskStore();
    this.dependencies = new TaskDependencyStore();
    this.graphViews = new GraphViewStore();
    this.eventLog = [];
    this.isOpen = mobx.observable.box(false);
    this.isDirty = mobx.observable.box(false);
  }

  reset() {
    this.tasks.reset();
    this.dependencies.reset();
    this.graphViews.reset();
    this.eventLog = [];
    this.isOpen.set(false);
    this.isDirty.set(false);
  }
}
