import * as React from "react";
import { stylesheet } from "typestyle";
import { gridBaseline } from "../../base/styles";
import { useTheme } from "../../themes/provider";
import { observer } from "mobx-react-lite";
import { NestedCSSProperties } from "typestyle/lib/types";

const areaStyles: NestedCSSProperties = {
  overflowX: "hidden",
  overflowY: "auto",
};

const styles = stylesheet({
  grid: {
    display: "grid",
    gridTemplateRows: `${gridBaseline * 6}px auto`,
    gridTemplateColumns: "300px 1fr",
    gridTemplateAreas: '"side-panel-header header" "side-panel content"',
    width: "100%",
    height: "100%",
    zIndex: 1,
  },

  sidePanelHeader: {
    gridArea: "side-panel-header",
    zIndex: 2,
  },

  sidePanel: {
    ...areaStyles,
    gridArea: "side-panel",
    zIndex: 2,
  },

  header: {
    gridArea:
      "side-panel-header / side-panel-header / side-panel-header / header",
    width: "100%",
    zIndex: 1,
  },

  contentWithSidePanel: {
    ...areaStyles,
    gridArea: "content",
    zIndex: 1,
  },

  contentWithoutSidePanel: {
    ...areaStyles,
    gridArea: "content / side-panel / content / content",
    zIndex: 1,
  },

  modal: {
    gridArea: "side-panel-header / content",
  },
});

type Props = {
  Header: React.ComponentType;
  SidePanelHeader?: React.ReactNode;
  SidePanel?: React.ReactNode;
  Modal?: React.ReactNode;
  fillUnderSidebar?: boolean;
};

export const AppGrid: React.FunctionComponent<Props> = observer(props => {
  const theme = useTheme();
  const sidePanel = props.SidePanel;

  return (
    <div className={styles.grid}>
      <div className={styles.modal}></div>
      <div className={styles.header}>{<props.Header />}</div>
      <div
        className={styles.sidePanelHeader}
        style={{
          backgroundColor: sidePanel ? theme.backgroundE : "transparent",
        }}
      >
        {props.SidePanelHeader && props.SidePanelHeader}
      </div>
      {sidePanel && (
        <div
          className={styles.sidePanel}
          style={{ backgroundColor: theme.backgroundC }}
        >
          {sidePanel}
        </div>
      )}
      <div
        className={
          sidePanel && !props.fillUnderSidebar
            ? styles.contentWithSidePanel
            : styles.contentWithoutSidePanel
        }
        style={{ backgroundColor: theme.backgroundB, color: theme.foregroundA }}
      >
        {props.children}
      </div>
    </div>
  );
});
