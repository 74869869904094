import { UiActions } from "./ui";
import { DomainActions } from "./domain";
import { AppStore } from "./data";
import { DomainAction } from "../persistence/log/action_definitions";
import { ActionResult, ErrorMessages } from "../persistence/log/errors";
import { SfxState } from "~sfx/sfx";
import { DomainStore } from "./data/domain";
import { doExport } from "~export/export";
import { Result } from "~base/result";

export class SfxActions {
  state: SfxState.T = SfxState.init();

  play = (sound: SfxState.Sound) => {
    SfxState.play(this.state, sound);
  };
}

export class AppActions {
  store: AppStore;

  sfx: SfxActions = new SfxActions();
  domain: DomainActions;
  ui: UiActions;

  constructor(store: AppStore, opts: { loadFromLocalStorage: boolean }) {
    this.store = store;

    this.sfx = new SfxActions();
    this.domain = new DomainActions(store.domain);

    this.ui = new UiActions(
      store,
      this.domain,
      { history: window.history },
      this.saveAction,
      this.sfx
    );
  }

  saveAction = (action: DomainAction): ActionResult => {
    const result = this.domain.saveAction(action);

    if (result.t === "err") {
      this.ui.notify(ErrorMessages[result.e]);
    }

    return result;
  };

  openBlankProject() {
    this.store.domain.reset();

    this.store.domain.isOpen.set(true);
  }

  recoverProject() {
    this.domain.recover(this.store.domain);
    this.ui.notify(
      `We've recovered your last project from your browser. Note that your browser could clear this data at any time, so it's best to save a copy of your project locally.`
    );
  }

  async openProjectLocally() {
    const result = await this.domain.loadFromFile();
    if (result == null) {
      // User cancelled
      alert("blag");
      return;
    }

    if (Result.isOk(result)) {
      this.ui.notify(`Project loaded with ${result.v.importedTasks} tasks`);
    } else {
      this.ui.notify(
        `I couldn't recognise this file, it might not be a TwigTask project`
      );
    }
  }

  openExample() {
    throw new Error("not implemented");
    //TODO
    //this.domain = DomainActions.load(this.store.domain);

    this.store.domain.isOpen.set(true);
  }

  saveProjectLocally() {
    doExport(this.store.domain);
    this.store.domain.isDirty.set(false);
  }
}
