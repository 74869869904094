import {
  IObservableValue,
  observable,
  ObservableMap,
  ObservableSet,
} from "mobx";
import {
  TaskDependencyId,
  TaskId,
} from "../../../../persistence/log/identifiers";

export type Task = {
  id: TaskId;
  title: IObservableValue<string>;
  description: IObservableValue<string>;
  status: IObservableValue<"none" | "in-progress" | "complete">;
  dependencies: ObservableSet<TaskDependencyId>;
};

export class TaskStore {
  items: ObservableMap<TaskId, Task>;
  unblocked: ObservableSet<TaskId>;

  constructor() {
    this.items = observable.map();
    this.unblocked = observable.set();
  }

  reset() {
    this.items.clear();
    this.unblocked.clear();
  }
}
