import { ActionType } from "~store/action-types";
import { actionCreator } from "~lib/action-creators";

export namespace UiFileActions {
  // Actions
  export type WaitlistSignupReset = {
    type: ActionType.UI_FILE_WAITLIST_SIGNUP_RESET;
  };
  export type WaitlistSignupRequest = {
    type: ActionType.UI_FILE_WAITLIST_SIGNUP_REQUEST;
    emailAddress: string;
  };
  export type WaitlistSignupSuccess = {
    type: ActionType.UI_FILE_WAITLIST_SIGNUP_SUCCESS;
    emailAddress: string;
  };
  export type WaitlistSignupError = {
    type: ActionType.UI_FILE_WAITLIST_SIGNUP_ERROR;
    message: string;
  };

  export const waitlistSignupReset = actionCreator<
    WaitlistSignupReset,
    ActionType
  >(ActionType.UI_FILE_WAITLIST_SIGNUP_RESET);

  export const waitlistSignupRequest = actionCreator<
    WaitlistSignupRequest,
    ActionType
  >(ActionType.UI_FILE_WAITLIST_SIGNUP_REQUEST);

  export const waitlistSignupSuccess = actionCreator<
    WaitlistSignupSuccess,
    ActionType
  >(ActionType.UI_FILE_WAITLIST_SIGNUP_SUCCESS);

  export const waitlistSignupError = actionCreator<
    WaitlistSignupError,
    ActionType
  >(ActionType.UI_FILE_WAITLIST_SIGNUP_ERROR);

  export type Action =
    | WaitlistSignupReset
    | WaitlistSignupRequest
    | WaitlistSignupSuccess
    | WaitlistSignupError;
}
