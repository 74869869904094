import { observable, ObservableMap } from "mobx";
import {
  TaskDependencyId,
  TaskId,
} from "../../../../persistence/log/identifiers";

/**
 * Dependencies are mapped from left -> right. If there are two tasks A and B,
 * and B depends on A, then A is `left` and B is `right`.
 */
export type TaskDependency = {
  id: TaskDependencyId;
  right: TaskId;
  left: TaskId;
};

export namespace TaskAnchor {
  export type T = "in" | "out";
  export const swap = (side: TaskAnchor.T) => (side === "in" ? "out" : "in");
}

export class TaskDependencyStore {
  items: ObservableMap<TaskDependencyId, TaskDependency>;

  constructor() {
    this.items = observable.map();
  }

  reset() {
    this.items.clear();
  }
}
