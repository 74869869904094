import { observer } from "mobx-react-lite";
import { AppActions } from "../state";
import {
  ContextMenu,
  ContextMenuContainer,
  ContextMenuItem,
} from "./ui/ContextMenu";
import * as React from "react";
import { graphViewDelete } from "../persistence/log/actions";

type Props = {
  app: AppActions;
};

export const ContextMenuView = observer((props: Props) => {
  const { app } = props;
  const uiState = app.store.ui.state.get();

  if (uiState.t !== "context_menu") {
    return null;
  }

  if (uiState.content.t === "graph_view") {
    const onClickRename = () => {
      app.ui.resetState();
      app.ui.graphViewNameEdit(uiState.content.graphViewId);
    };
    const onClickDelete = () => {
      app.ui.resetState();
      app.domain.saveAction(graphViewDelete(uiState.content.graphViewId));
    };
    const onClickOutside = () => {
      app.ui.resetState();
    };
    return (
      <ContextMenuContainer>
        <ContextMenu
          onClickOutside={onClickOutside}
          left={uiState.left}
          top={uiState.top}
        >
          <ContextMenuItem onClick={onClickRename}>Rename</ContextMenuItem>
          <ContextMenuItem onClick={onClickDelete}>Delete</ContextMenuItem>
        </ContextMenu>
      </ContextMenuContainer>
    );
  }

  return null;
});
