import { GraphViewId, TaskId } from "~persistence/log/identifiers";
import { View } from "./types";
import { actionCreator } from "~lib/action-creators";
import { ActionType } from "~store/action-types";
import { RootAction } from "~store/actions";
import { UiModalActions } from "./modal/actions";
import { UiModalStore } from "./modal/store";
import { UiFileActions } from "./file/actions";
import { UiFileStore } from "./file/store";

export namespace UiStore {
  export type PathChanged = { type: ActionType.UI_PATH_CHANGED; path: string };
  export type PathSet = { type: ActionType.UI_PATH_SET; path: string };
  export type ProjectLoaded = { type: ActionType.UI_PROJECT_LOADED };

  export const pathChanged = actionCreator<PathChanged, ActionType>(
    ActionType.UI_PATH_CHANGED
  );
  export const pathSet = actionCreator<PathSet, ActionType>(
    ActionType.UI_PATH_SET
  );
  export const projectLoaded = actionCreator<ProjectLoaded, ActionType>(
    ActionType.UI_PROJECT_LOADED
  );

  export type Action =
    | UiModalActions.Action
    | UiFileActions.Action
    | PathChanged
    | PathSet
    | ProjectLoaded;

  export type State = {
    modal: UiModalStore.State;
    file: UiFileStore.State;
    path: string;
    view: View;
  };

  export const initialState = (): State => ({
    modal: UiModalStore.initialState(),
    file: UiFileStore.initialState(),
    path: "/",
    view: { view: "home" },
  });

  export const reducer = (state: State, action: RootAction) => {
    switch (action.type) {
      case ActionType.UI_PATH_CHANGED:
        return { ...state, path: action.path, view: viewForRoute(action.path) };
    }

    return {
      ...state,
      modal: UiModalStore.reducer(state.modal, action),
      file: UiFileStore.reducer(state.file, action),
    };
  };
}

const viewForRoute = (path: string): View => {
  const homeRoute = /^\/?$/;
  const taskRoute = /^\/task\/([0-9A-z]+)$/;
  const goalsRoute = /^\/goals/;
  const saveRoute = /^\/save/;
  const waitlistRoute = /^\/waitlist/;
  const unblockedRoute = /^\/unblocked$/;
  const settingsRoute = /^\/settings$/;
  const graphViewRoute = /^\/graph\/([0-9A-z]+)$/;

  let result;
  if (homeRoute.test(path)) {
    return { view: "home" };
  } else if ((result = goalsRoute.exec(path))) {
    return { view: "goals" };
  } else if ((result = saveRoute.exec(path))) {
    return { view: "save" };
  } else if ((result = waitlistRoute.exec(path))) {
    return { view: "join_waitlist" };
  } else if ((result = unblockedRoute.exec(path))) {
    return { view: "unblocked" };
  } else if ((result = settingsRoute.exec(path))) {
    return { view: "settings" };
  } else if ((result = taskRoute.exec(path))) {
    return { view: "task", taskId: result[1] as TaskId };
  } else if ((result = graphViewRoute.exec(path))) {
    return {
      view: "graph",
      graphViewId: result[1] as GraphViewId,
    };
    // TODO: Move this to an effect
    //this.store.ui.graphView.cameraOrigin.set({ x: 0, y: 0 });
  } else {
    return { view: "not_found", message: "Page not found" };
  }
};
