import { FuzzyFinderStore, SearchResult } from "../../data/ui/fuzzy_finder";

export namespace FuzzyFinderActions {
  export const onChangeQuery = <K>(
    finder: FuzzyFinderStore<K>,
    searchFn: (query: string) => SearchResult<K>[],
    query: string
  ) => {
    let results: SearchResult<K>[] = [];
    if (query !== "") {
      results = searchFn(query);
    }
    finder.options.replace(results.sort((a, b) => b.score - a.score));
    finder.selectedIndex.set(0);
    finder.query.set(query);
  };

  export const onSelectNext = <K>(finder: FuzzyFinderStore<K>) => {
    const index = finder.selectedIndex.get();
    if (index >= finder.options.length - 1) {
      finder.selectedIndex.set(0);
    } else {
      finder.selectedIndex.set(index + 1);
    }
  };

  export const onSelectPrevious = <K>(finder: FuzzyFinderStore<K>) => {
    const index = finder.selectedIndex.get();
    if (index <= 0) {
      finder.selectedIndex.set(finder.options.length - 1);
    } else {
      finder.selectedIndex.set(index - 1);
    }
  };

  export const onSelectIndex = <K>(
    finder: FuzzyFinderStore<K>,
    onSelect: (key: K, query: string) => void,
    index: number
  ) => {
    const query = finder.query.get();
    onSelect(finder.options[index].key, query);

    finder.query.set("");
    finder.options.replace([]);
    finder.selectedIndex.set(0);
  };

  export const onSelectCurrent = <K>(
    finder: FuzzyFinderStore<K>,
    onSelect: (key: K, query: string) => void
  ) => {
    onSelectIndex(finder, onSelect, finder.selectedIndex.get());
  };
}
