import { withStyles } from "../themes/provider";
import { style, stylesheet } from "typestyle";
import { Theme } from "../themes/theme";
import * as React from "react";
import { gridBaseline } from "./styles";
import classNames from "classnames";

const baseStyles = (theme: Theme) => ({
  fontSize: gridBaseline * 2,
  color: theme.foregroundA,
  textDecoration: "none",
  fontFamily: "'Roboto', sans-serif",
});

const textStyles = (theme: Theme) =>
  stylesheet({
    common: { fontFamily: "'Roboto', sans-serif" },
    sizeNormal: { fontSize: gridBaseline * 2 },
    sizeH1: { fontSize: gridBaseline * 4 },
    sizeH2: { fontSize: gridBaseline * 3 },
    sizeH5: { fontSize: gridBaseline * 2, fontWeight: "bold" },
    colorNormal: { color: theme.foregroundA },
    colorMuted: { color: theme.foregroundB },
    decorationNone: { textDecoration: "none" },
    decorationStrike: { textDecoration: "line-through" },
    pill: {
      backgroundColor: theme.backgroundG,
      color: theme.foregroundA,
      fontSize: gridBaseline * 1.5,
      fontVariant: "small-caps",
      borderRadius: 2,
      padding: `0 2px`,
    },
  });

const h1Styles = (theme: Theme) => ({
  ...baseStyles(theme),
  fontSize: gridBaseline * 4,
  margin: 0,
});

const h2Styles = (theme: Theme) => ({
  ...baseStyles(theme),
  fontSize: gridBaseline * 3,
  margin: 0,
});

export const TextNormalStyles = (theme: Theme) => style(baseStyles(theme));
export const Heading1Styles = (theme: Theme) => style(h1Styles(theme));
export const Heading2Styles = (theme: Theme) => style(h2Styles(theme));

type Props = {
  className?: string;
};

export const Heading1: React.ComponentType<Props> = withStyles(
  Heading1Styles
)(({ styles, children, className }) => (
  <h1 className={classNames(styles, className)}>{children}</h1>
));

export const Heading2: React.ComponentType<Props> = withStyles(
  Heading2Styles
)(({ styles, children, className }) => (
  <h2 className={classNames(styles, className)}>{children}</h2>
));

export const TextNormal: React.ComponentType<Props> = withStyles(
  TextNormalStyles
)(({ styles, children, className }) => (
  <span className={classNames(styles, className)}>{children}</span>
));

export const Paragraph: React.FunctionComponent = ({ children }) => (
  <p>
    <TextNormal>{children}</TextNormal>
  </p>
);

export type TextProps = {
  strikethrough?: boolean;
  muted?: boolean;
  level: "p" | "h1" | "h2" | "h5" | "pill";
};
const LEVEL_MAP: Record<
  "p" | "h1" | "h2" | "h5" | "pill",
  "sizeNormal" | "sizeH1" | "sizeH2" | "sizeH5" | "pill"
> = { p: "sizeNormal", h1: "sizeH1", h2: "sizeH2", h5: "sizeH5", pill: "pill" };

export const Text: React.FunctionComponent<TextProps> = withStyles(textStyles)(
  props => {
    const { styles, children } = props;
    let classes = classNames(
      styles.common,
      props.muted ? styles.colorMuted : styles.colorNormal,
      props.strikethrough ? styles.decorationStrike : styles.decorationNone,
      styles[LEVEL_MAP[props.level]]
    );

    return <span className={classes}>{children}</span>;
  }
);

export const TextCompleteStyles = (theme: Theme) =>
  style({
    ...baseStyles(theme),
    textDecoration: "line-through",
    color: theme.foregroundB,
  });

export const TextComplete: React.ComponentType<Props> = withStyles(
  TextCompleteStyles
)(({ styles, children, className }) => (
  <span className={classNames(styles, className)}>{children}</span>
));

export const Pill: React.ComponentType<Props> = withStyles(
  textStyles
)(({ styles, children }) => <span className={styles.pill}>{children}</span>);
