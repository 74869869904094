import * as React from "react";
import { useTheme } from "../themes/provider";

export type IconCommonProps = {
  size: number;
};

export const GoalIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8828 14.343C18.9716 12.3667 19.2784 10.0534 18.7423 7.86157C18.2061 5.66974 16.8662 3.75926 14.988 2.50868C13.1098 1.2581 10.8304 0.758674 8.60131 1.10935C6.37227 1.46003 4.35628 2.63522 2.95278 4.40208C1.54929 6.16894 0.860693 8.39854 1.02344 10.6491C1.18619 12.8997 2.1884 15.007 3.8315 16.5536C5.47459 18.1001 7.63869 18.9731 9.89499 18.9994C12.1513 19.0257 14.3352 18.2035 16.0139 16.6957L15.3881 15.999C13.884 17.3499 11.9274 18.0865 9.90592 18.0629C7.88441 18.0393 5.94552 17.2572 4.4734 15.8716C3.00129 14.486 2.10337 12.598 1.95756 10.5816C1.81175 8.56519 2.42868 6.56761 3.68613 4.98461C4.94358 3.40161 6.74978 2.34871 8.74686 2.03453C10.7439 1.72034 12.7862 2.1678 14.4689 3.28824C16.1517 4.40868 17.3522 6.12035 17.8325 8.0841C18.3129 10.0479 18.038 12.1204 17.0625 13.8911L17.8828 14.343Z"
        fill={foregroundA}
      />
      <path
        d="M15.4835 12.4354C16.0739 11.1061 16.1613 9.60758 15.7295 8.21866C15.2976 6.82975 14.376 5.64498 13.136 4.88477C11.896 4.12455 10.422 3.84066 8.98835 4.0859C7.55468 4.33114 6.2589 5.08882 5.34207 6.21797C4.42525 7.34713 3.94981 8.77088 4.0042 10.2244C4.05858 11.6778 4.6391 13.0621 5.63775 14.1196C6.63639 15.177 7.98517 15.8358 9.43316 15.9732C10.8812 16.1106 12.3298 15.7173 13.5095 14.8666L12.975 14.1253C11.9749 14.8465 10.7469 15.1799 9.5195 15.0634C8.29205 14.9469 7.1487 14.3885 6.30216 13.4921C5.45562 12.5957 4.96352 11.4223 4.91742 10.1902C4.87132 8.95809 5.27434 7.75119 6.05152 6.79402C6.82871 5.83684 7.92712 5.19457 9.14243 4.98668C10.3577 4.77879 11.6072 5.01945 12.6583 5.66387C13.7095 6.30829 14.4908 7.31261 14.8568 8.48998C15.2229 9.66735 15.1488 10.9376 14.6483 12.0644L15.4835 12.4354Z"
        fill={foregroundA}
      />
      <path
        d="M13 10C13 9.39124 12.8148 8.79688 12.469 8.29587C12.1232 7.79486 11.6332 7.41091 11.064 7.19501C10.4948 6.97911 9.87337 6.94148 9.28229 7.08712C8.69121 7.23275 8.15842 7.55477 7.75468 8.01039C7.35095 8.46601 7.09538 9.03367 7.02192 9.63799C6.94846 10.2423 7.06059 10.8547 7.34341 11.3937C7.62622 11.9328 8.06635 12.3731 8.60533 12.6561C9.1443 12.9391 9.75663 13.0515 10.361 12.9782L10.2365 11.9513C9.84054 11.9993 9.43935 11.9257 9.08622 11.7403C8.73308 11.5548 8.44471 11.2664 8.25941 10.9132C8.07411 10.56 8.00065 10.1588 8.04878 9.76281C8.09691 9.36687 8.26436 8.99494 8.52888 8.69642C8.7934 8.3979 9.14249 8.18691 9.52976 8.09149C9.91703 7.99607 10.3242 8.02073 10.6971 8.16218C11.07 8.30364 11.3911 8.55521 11.6177 8.88346C11.8442 9.21172 11.9656 9.60114 11.9656 10H13Z"
        fill={foregroundA}
      />
      <line
        x1="9.30697"
        y1="9.60532"
        x2="18.307"
        y2="16.6053"
        stroke={foregroundA}
      />
    </svg>
  );
};

export const ListIcon = (props: IconCommonProps) => {
  const { foregroundA, foregroundB } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="5" cy="4" r="2" fill={foregroundA} />
      <circle cx="5" cy="10" r="2" fill={foregroundA} />
      <circle cx="5" cy="16" r="2" fill={foregroundB} />
      <rect x="8" y="3" width="9" height="2" fill={foregroundA} />
      <rect x="8" y="15" width="9" height="2" fill={foregroundB} />
      <rect x="8" y="9" width="9" height="2" fill={foregroundA} />
    </svg>
  );
};

export const DisconnectIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="3"
        y1="10"
        x2="6"
        y2="10"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <line
        x1="15"
        y1="10"
        x2="18"
        y2="10"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M7.08334 13.5L13.4167 7" stroke={foregroundA} strokeWidth="2" />
      <path d="M7 7.08334L13.5 13.4167" stroke={foregroundA} strokeWidth="2" />
    </svg>
  );
};

export const MenuIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="12" height="2" rx="1" fill={foregroundA} />
      <rect x="4" y="9" width="12" height="2" rx="1" fill={foregroundA} />
      <rect x="4" y="14" width="12" height="2" rx="1" fill={foregroundA} />
    </svg>
  );
};

export const GraphIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 10C14 10 4 4 14.5 4" stroke={foregroundA} />
      <circle cx="3" cy="10" r="2" fill={foregroundA} />
      <circle cx="16" cy="4" r="2" fill={foregroundA} />
      <circle cx="16" cy="16" r="2" fill={foregroundA} />
      <path d="M3 10C14.4545 10 3.54545 16 15 16" stroke={foregroundA} />
    </svg>
  );
};

type SelectionIconProps = IconCommonProps & {
  selected: boolean;
};

export const SelectionIcon: React.FunctionComponent<SelectionIconProps> = props => {
  const theme = useTheme();

  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.5"
        y="4.5"
        width="12"
        height="12"
        rx="1.5"
        stroke={theme.foregroundA}
        strokeDasharray="3 3"
      />
      <circle
        cx="4.5"
        cy="4.5"
        r={props.selected ? "4" : "2.5"}
        fill={props.selected ? theme.backgroundA : theme.backgroundG}
      ></circle>
    </svg>
  );
};

export const GraphNewIcon = (props: IconCommonProps) => {
  const { foregroundA, foregroundB } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3.5 10C14 10 4 4 14.5 4" stroke={foregroundB} />
      <circle cx="3" cy="10" r="2" fill={foregroundB} />
      <circle cx="16" cy="4" r="2" fill={foregroundB} />
      <circle cx="16" cy="16" r="2" fill={foregroundB} />
      <path d="M3 10C14.4545 10 3.54545 16 15 16" stroke={foregroundB} />
      <line x1="2" y1="4.5" x2="7" y2="4.5" stroke={foregroundA} />
      <line x1="4.5" y1="2" x2="4.5" y2="7" stroke={foregroundA} />
    </svg>
  );
};

export const CompleteIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 10.3571L8.66667 12.5L14 7"
        className="svg-str-col-fg-a"
        strokeWidth="2"
      />
      <circle cx="10" cy="10" r="7.5" stroke={foregroundA} />
    </svg>
  );
};

export const CancelIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 13.5L13.3333 7"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6.91667 7.08334L13.4167 13.4167"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const PlayIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 10L6.5 14.3301L6.5 5.66987L14 10Z"
        className="svg-fill-col-fg-a"
        fill={foregroundA}
      />
    </svg>
  );
};

export const AddIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="10"
        y1="5"
        x2="10"
        y2="15"
        stroke={foregroundA}
        strokeWidth="2"
      />
      <line
        x1="15"
        y1="10"
        x2="5"
        y2="10"
        stroke={foregroundA}
        strokeWidth="2"
      />
    </svg>
  );
};

export const HideIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 8C5.3 8 3.72917 9.92308 3 10.6923C3.875 11.5897 5.125 13 9.5 13C13.875 13 15.9167 11.5897 16.5 10.6923C16.0625 9.92308 13.7 8 9.5 8Z"
        stroke={foregroundA}
      />
      <circle cx="9.5" cy="10.5" r="2.5" fill={foregroundA} />
    </svg>
  );
};

export const EllipsesIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7" cy="13" r="1" fill={foregroundA} />
      <circle cx="10" cy="13" r="1" fill={foregroundA} />
      <circle cx="13" cy="13" r="1" fill={foregroundA} />
    </svg>
  );
};

export const ChevronDownIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="4.96028"
        y1="7.84767"
        x2="10.9603"
        y2="12.8477"
        stroke={foregroundA}
        strokeWidth="3"
      />
      <line
        x1="9.03972"
        y1="12.8477"
        x2="15.0397"
        y2="7.84767"
        stroke={foregroundA}
        strokeWidth="3"
      />
    </svg>
  );
};

export const SelectionToolIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8787 17.1816L6.15147 6.15147L17.1816 10.8787L12.1763 12.1763L10.8787 17.1816Z"
        stroke={foregroundA}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8546 10.8949L3.11979 10.2554C3.20046 10.2889 3.28947 10.3077 3.38462 10.3077H4.16346V11H3.38462C3.19687 11 3.01786 10.9626 2.8546 10.8949ZM11 4.16346H10.3077V3.38462C10.3077 3.28947 10.2889 3.20046 10.2554 3.11979L10.8949 2.8546C10.9626 3.01786 11 3.19687 11 3.38462V4.16346ZM4.16346 2H3.38462C3.19687 2 3.01786 2.03737 2.8546 2.10507L3.11979 2.74457C3.20046 2.71112 3.28947 2.69231 3.38462 2.69231H4.16346V2ZM2 8.83654H2.69231V9.61538C2.69231 9.71053 2.71112 9.79954 2.74457 9.88021L2.10507 10.1454C2.03737 9.98214 2 9.80313 2 9.61538V8.83654ZM2 7.27885H2.69231V5.72115H2V7.27885ZM2 4.16346H2.69231V3.38462C2.69231 3.28947 2.71112 3.20046 2.74457 3.11979L2.10507 2.8546C2.03737 3.01786 2 3.19687 2 3.38462V4.16346ZM5.72115 2V2.69231H7.27885V2H5.72115ZM8.83654 2V2.69231H9.61538C9.71053 2.69231 9.79954 2.71112 9.88021 2.74457L10.1454 2.10507C9.98214 2.03737 9.80313 2 9.61538 2H8.83654Z"
        fill={foregroundA}
      />
    </svg>
  );
};

export const MoveToolIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  const stroke = foregroundA;
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.5 7.5V12.5" stroke={stroke} strokeLinecap="round" />
      <line
        x1="13.5"
        y1="8.5"
        x2="13.5"
        y2="9.5"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path
        d="M13.5 7.5C13.5 7.5 13.5 6 14.5 6C15.5 6 15.5 7.5 15.5 7.5"
        stroke={stroke}
      />
      <line
        x1="13.5"
        y1="5.5"
        x2="13.5"
        y2="9.5"
        stroke={stroke}
        strokeLinecap="round"
      />
      <line
        x1="11.5"
        y1="6.5"
        x2="11.5"
        y2="9.5"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path
        d="M11.5 5.5C11.5 5.5 11.5 4 12.5 4C13.5 4 13.5 5.5 13.5 5.5"
        stroke={stroke}
      />
      <line
        x1="11.5"
        y1="4.5"
        x2="11.5"
        y2="7.5"
        stroke={stroke}
        strokeLinecap="round"
      />
      <line
        x1="9.5"
        y1="4.5"
        x2="9.5"
        y2="7.5"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path
        d="M9.5 4.5C9.5 4.5 9.5 3 10.5 3C11.5 3 11.5 4.5 11.5 4.5"
        stroke={stroke}
      />
      <line
        x1="9.5"
        y1="6.5"
        x2="9.5"
        y2="9.5"
        stroke={stroke}
        strokeLinecap="round"
      />
      <path d="M7.5 5.5L7.5 12.5" stroke={stroke} strokeLinecap="round" />
      <path
        d="M7.5 5.5C7.5 5.5 7.5 4 8.5 4C9.5 4 9.5 5.5 9.5 5.5"
        stroke={stroke}
      />
      <path
        d="M7.5 12.5C6.83333 11.6667 5.01193 8.99404 4 9.5C3 10 4.5 12 5 13C5.5 14 7 17 10.5 17.5C14 18 15.5 15 15.5 12.5"
        stroke={stroke}
      />
    </svg>
  );
};

export const InfoIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  const stroke = foregroundA;

  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="10"
        y1="10"
        x2="10"
        y2="13"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="10" cy="7" r="1" fill={stroke} />
      <circle cx="10" cy="10" r="7.5" stroke={stroke} />
    </svg>
  );
};

export const NewIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  const stroke = foregroundA;

  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="12.5" y1="9" x2="12.5" y2="12" stroke={stroke} />
      <line x1="10" y1="6.5" x2="7" y2="6.5" stroke={stroke} />
      <line x1="18" y1="6.5" x2="15" y2="6.5" stroke={stroke} />
      <line x1="12.5" y1="1" x2="12.5" y2="4" stroke={stroke} />
      <line
        x1="10.3536"
        y1="8.35355"
        x2="8.35355"
        y2="10.3536"
        stroke={stroke}
      />
      <line
        y1="-0.5"
        x2="2.82843"
        y2="-0.5"
        transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 10 5)"
        stroke={stroke}
      />
      <line
        y1="-0.5"
        x2="2.82843"
        y2="-0.5"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 15 8)"
        stroke={stroke}
      />
      <line
        x1="14.6464"
        y1="4.64645"
        x2="16.6464"
        y2="2.64645"
        stroke={stroke}
      />
      <line x1="6" y1="5.5" x2="3" y2="5.5" stroke={stroke} />
      <line x1="3.5" y1="5" x2="3.5" y2="17" stroke={stroke} />
      <line x1="3" y1="16.5" x2="12" y2="16.5" stroke={stroke} />
      <line x1="12.5" y1="17" x2="12.5" y2="13" stroke={stroke} />
    </svg>
  );
};

export const OpenIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();

  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6473 17H2.5V8.75H3.82857M14.6473 17V8.75H3.82857M14.6473 17L17 6H13.5M3.82857 8.75L4.5 6H7"
        stroke={foregroundA}
      />
      <path d="M7 8.5V2.5H11L13 4.5V9" stroke={foregroundA} />
      <path d="M11 3V5H13" stroke={foregroundA} />
    </svg>
  );
};

export const UndoIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-2-inside-1" fill="white">
        <path d="M5 10C5 8.71668 5.41147 7.46717 6.17399 6.43495C6.93651 5.40272 8.0099 4.64218 9.23654 4.265C10.4632 3.88782 11.7784 3.91387 12.9892 4.33933C14.1999 4.76479 15.2424 5.56724 15.9634 6.62885C16.6844 7.69045 17.0461 8.95528 16.9953 10.2376C16.9445 11.5199 16.4838 12.7521 15.6811 13.7534C14.8783 14.7546 13.7756 15.472 12.535 15.8003C11.2944 16.1286 9.98117 16.0505 8.78822 15.5775L9.36286 14.1284C10.2459 14.4785 11.2179 14.5364 12.1362 14.2933C13.0545 14.0503 13.8706 13.5193 14.4649 12.7782C15.0591 12.0371 15.4 11.125 15.4377 10.1759C15.4753 9.22671 15.2076 8.2905 14.6739 7.50471C14.1401 6.71891 13.3686 6.12495 12.4724 5.81003C11.5762 5.49511 10.6026 5.47582 9.6947 5.75501C8.78675 6.0342 7.99224 6.59714 7.42783 7.36118C6.86342 8.12522 6.55886 9.0501 6.55886 10L5 10Z" />
      </mask>
      <path
        d="M5 10C5 8.71668 5.41147 7.46717 6.17399 6.43495C6.93651 5.40272 8.0099 4.64218 9.23654 4.265C10.4632 3.88782 11.7784 3.91387 12.9892 4.33933C14.1999 4.76479 15.2424 5.56724 15.9634 6.62885C16.6844 7.69045 17.0461 8.95528 16.9953 10.2376C16.9445 11.5199 16.4838 12.7521 15.6811 13.7534C14.8783 14.7546 13.7756 15.472 12.535 15.8003C11.2944 16.1286 9.98117 16.0505 8.78822 15.5775L9.36286 14.1284C10.2459 14.4785 11.2179 14.5364 12.1362 14.2933C13.0545 14.0503 13.8706 13.5193 14.4649 12.7782C15.0591 12.0371 15.4 11.125 15.4377 10.1759C15.4753 9.22671 15.2076 8.2905 14.6739 7.50471C14.1401 6.71891 13.3686 6.12495 12.4724 5.81003C11.5762 5.49511 10.6026 5.47582 9.6947 5.75501C8.78675 6.0342 7.99224 6.59714 7.42783 7.36118C6.86342 8.12522 6.55886 9.0501 6.55886 10L5 10Z"
        stroke={foregroundA}
        strokeWidth="14"
        strokeLinecap="round"
        mask="url(#path-2-inside-1)"
      />
      <path
        d="M3.85 9L5.85 11L7.85 9"
        stroke={foregroundA}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const DownloadIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="10"
        y1="4"
        x2="10"
        y2="10"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8 10L10 12L12 10"
        stroke={foregroundA}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="4"
        y1="15"
        x2="16"
        y2="15"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CloudIcon = (props: IconCommonProps) => {
  const { foregroundA } = useTheme();
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 10L16 10" stroke={foregroundA} strokeLinecap="round" />
      <path
        d="M6.5 5C1.5 4.5 1.5 9.5 4 10"
        stroke={foregroundA}
        strokeLinecap="round"
      />
      <path
        d="M14 5C18.5 4.5 19.5 10 16 10"
        stroke={foregroundA}
        strokeLinecap="round"
      />
      <path
        d="M6.5 5C6.5 2.38419e-07 14 -2.38419e-07 14 5"
        stroke={foregroundA}
        strokeLinecap="round"
      />
      <line
        x1="7"
        y1="18"
        x2="7"
        y2="15"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9 15L7 13L5 15"
        stroke={foregroundA}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <line
        x1="13"
        y1="13"
        x2="13"
        y2="16"
        stroke={foregroundA}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 16L13 18L15 16"
        stroke={foregroundA}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
