import * as React from "react";
import { ReadableColumn } from "~view/layout/ReadableColumn";
import { VerticalCenter } from "~view/layout/VerticalCenter";
import { useTheme } from "~themes/provider";
import { stylesheet } from "typestyle";
//import homeBg from "~assets/home-bg.png";
import { gridBaseline } from "~base/styles";

const styles = stylesheet({
  focusBox: {
    padding: gridBaseline * 4,
    borderRadius: gridBaseline / 2,
  },
  focusBoxContainer: {
    //backgroundImage: `url('${homeBg}')`,
    //backgroundRepeat: "no-repeat",
    //backgroundSize: "cover",
    width: "100%",
    height: "100%",
  },
});

/**
 * A box that appears in the centre of its container, sort of like a modal
 * but there's nothing underneath it.
 */
export const FocusBox: React.FunctionComponent = props => {
  const theme = useTheme();
  return (
    <div className={styles.focusBoxContainer}>
      <VerticalCenter>
        <ReadableColumn maxWidth={gridBaseline * 64}>
          <div
            className={styles.focusBox}
            style={{
              background: theme.backgroundA,
              border: "1px solid " + theme.backgroundD,
            }}
          >
            {props.children}
          </div>
        </ReadableColumn>
      </VerticalCenter>
    </div>
  );
};
