import * as React from "react";

type Props = {
  value: string;
  setValue: (newValue: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  id?: string;
};

export const TextInput = (props: Props) => {
  return (
    <input
      type="text"
      id={props.id}
      value={props.value}
      autoFocus={props.autoFocus}
      className="flex-grow-1 border-1 border-col-bg-c bg-col-bg-a pad-3 text-normal"
      placeholder={props.placeholder}
      onChange={e => props.setValue(e.currentTarget.value)}
    />
  );
};
