export namespace Vec2 {
  export type T = {
    x: number;
    y: number;
  };

  export const clone = (a: T) => ({ x: a.x, y: a.y });

  export const add = (a: T, b: T) => ({
    x: a.x + b.x,
    y: a.y + b.y,
  });
  export const subtract = (a: T, b: T) => ({
    x: a.x - b.x,
    y: a.y - b.y,
  });
  export const mul = (a: T, scalar: number) => ({
    x: a.x * scalar,
    y: a.y * scalar,
  });
  export const eq = (a: T, b: T) => a.x === b.x && a.y === b.y;

  export const dist = (a: T, b: T) => Math.hypot(a.x - b.x, a.y - b.y);

  export const origin = () => ({
    x: 0,
    y: 0,
  });
}
