import "./themes/auto.css";
import "./style.css";
import "./layout.css";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";
import { TaskList } from "./view/TaskList";
import { TaskId } from "./persistence/log/identifiers";
import { TaskView } from "./view/TaskView";
import { NavBar } from "./view/NavBar";
import { AppActions } from "./state";
import { GraphViewLayout } from "./view/graph/GraphViewLayout";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Toast, ToastContainer } from "./view/ui/Toast";
import { ContextSidebar } from "./view/ContextSidebar";
import { ObservableSet } from "mobx";
import { ContextMenuView } from "./view/ContextMenuView";
import { stylesheet, forceRenderStyles } from "typestyle";
import { NestedCSSProperties } from "typestyle/lib/types";
import { useMeasurableContext } from "./components/Measurable";
import { AppGrid } from "./view/layout/AppGrid";
import { AppHeader } from "./view/AppHeader";
import { Stack } from "./view/layout/Stack";
import { FloatRight } from "./view/layout/FloatRight";
import { TabButton } from "./view/ui/button/Button";
import { SelectionIcon, CancelIcon, MenuIcon } from "./components/Icons";
import { WelcomeScreen } from "~view/onboarding/WelcomeScreen";
import { useAppState, AppStateProvider } from "~app_provider";
import { ReadableColumn } from "~view/layout/ReadableColumn";
import { Modal } from "~view/modals";
import { Provider as ReduxProvider } from "react-redux";
import { createStore, createDummyStore, useSelector } from "~store/store";
import { AppStore } from "~state/data";
import { Text } from "~base/typography";
import { SaveScreen } from "~view/file/save/SaveScreen";
import { WaitlistSignup } from "~view/file/save/WaitlistSignup";

const fill: NestedCSSProperties = {
  position: "absolute",
  width: "100%",
  height: "100%",
};

const styles = stylesheet({
  appContainer: {
    ...fill,
    zIndex: 0,
  },
  overlays: {
    ...fill,
    zIndex: 2,
    pointerEvents: "none",
  },
  content: {
    ...fill,
    zIndex: 1,
  },
});

const CurrentView = observer(() => {
  const app = useAppState();
  const onChooseTask = (task: TaskId) => {
    app.store.ui.selection.replace([task]);
    app.store.ui.sidePanel.set({ t: "selection" });
  };
  const view = useSelector(state => state.ui.view);

  const tasks = app.domain.getTopLevelTasks();
  const isOpen = app.store.domain.isOpen.get();

  if (!isOpen) {
    return <WelcomeScreen />;
  }

  switch (view.view) {
    case "home":
      return <WelcomeScreen />;
    case "save":
      return <SaveScreen />;
    case "join_waitlist":
      return <WaitlistSignup />;
    case "goals":
      return (
        <div className="pad-3">
          <ReadableColumn>
            <Stack direction="column">
              <Text level="h1">Goals</Text>
              <Text level="p">
                This view shows all final tasks - those with nothing depending
                on them.
              </Text>
              <TaskList
                tasks={tasks}
                onChooseTask={onChooseTask}
                selected={new ObservableSet<TaskId>(app.store.ui.selection)}
              />
            </Stack>
          </ReadableColumn>
        </div>
      );
    case "unblocked": {
      const tasks = app.domain.tasks
        .findByIds(Array.from(app.store.domain.tasks.unblocked))
        .filter(t => t.status.get() !== "complete");
      return (
        <div className="pad-3">
          <ReadableColumn>
            <Stack direction="column">
              <Text level="h1">Unblocked Tasks</Text>
              <Text level="p">
                This view shows all tasks that are ready to work on. Unblocked
                tasks are tasks that aren't currently blocked by other
                incomplete tasks.
              </Text>
              <TaskList
                tasks={tasks}
                onChooseTask={onChooseTask}
                unblocked={app.store.domain.tasks.unblocked}
                selected={new ObservableSet<TaskId>(app.store.ui.selection)}
              />
            </Stack>
          </ReadableColumn>
        </div>
      );
    }
    case "task":
      return <TaskView taskId={view.taskId} onChooseTask={onChooseTask} />;
    case "graph":
      return <GraphViewLayout graphViewId={view.graphViewId} />;
    case "not_found":
    default:
      return <div>Page not found</div>;
  }
});

const SidePanel = observer((props: { app: AppActions }) => {
  const sidePanel = props.app.store.ui.sidePanel.get();
  switch (sidePanel.t) {
    case "collapsed":
      return null;
    case "menu":
      return <NavBar app={props.app} />;
    case "selection":
      return <ContextSidebar app={props.app} />;
  }
});

const App = observer(() => {
  const app = useAppState();
  const measurableContext = useMeasurableContext();
  const onClickMenuTab = () => {
    app.ui.toggleSidePanel("menu");
    measurableContext.notifyChange();
  };
  const onClickSelectionTab = () => {
    app.ui.toggleSidePanel("selection");
    measurableContext.notifyChange();
  };
  const onClickSidePanelCollapse = () => {
    app.ui.toggleSidePanel("collapsed");
    measurableContext.notifyChange();
  };

  const CloseButton = () => (
    <TabButton
      onClick={onClickSidePanelCollapse}
      Icon={CancelIcon}
      title="Close"
    />
  );

  const SidePanelHeader = observer(() => {
    const sidePanel = app.store.ui.sidePanel.get();

    const tabs = (
      <Stack direction="row" spacing={0}>
        <TabButton
          onClick={onClickMenuTab}
          active={sidePanel.t === "menu"}
          Icon={MenuIcon}
          title="Menu"
        />
        <TabButton
          onClick={onClickSelectionTab}
          active={sidePanel.t === "selection"}
          title="Selection Information"
          Icon={observer((props: { size: number }) => (
            <SelectionIcon
              selected={app.store.ui.selection.length > 0}
              size={props.size}
            />
          ))}
        />
      </Stack>
    );

    return sidePanel.t != "collapsed" ? (
      <FloatRight Floater={CloseButton}>{tabs}</FloatRight>
    ) : (
      tabs
    );
  });

  const isOpen = app.store.domain.isOpen.get();
  const showSidePanel = isOpen && app.store.ui.sidePanel.get().t != "collapsed";
  const view = useSelector(state => state.ui.view);
  const fillUnderSidebar = view.view === "graph";

  return (
    <div className={styles.appContainer}>
      <div className={styles.overlays}>
        <Modal />
        <ToastContainer>
          {app.store.ui.notifications.map(notification => {
            const onClickClose = () => {
              app.ui.removeNotification(notification.id);
            };
            return (
              <Toast type="notice" onClickClose={onClickClose}>
                {notification.text}
              </Toast>
            );
          })}
        </ToastContainer>
        <ContextMenuView app={app} />
      </div>
      <div className={styles.content}>
        <AppGrid
          fillUnderSidebar={fillUnderSidebar}
          Header={AppHeader}
          SidePanel={showSidePanel && <SidePanel app={app} />}
          SidePanelHeader={isOpen && <SidePanelHeader />}
        >
          <ErrorBoundary note="View">
            <CurrentView />
          </ErrorBoundary>
        </AppGrid>
      </div>
    </div>
  );
});

declare var window: {
  tools: any;
};
window.tools = {
  reset: () => {
    localStorage.removeItem("log");
    location.reload();
  },
};

// What a hack job. It's ok, this is temporary until I burn mobx
const legacyStore = new AppStore(createDummyStore());
const legacyApp = new AppActions(legacyStore, { loadFromLocalStorage: true });
const store = createStore({ legacyApp });
legacyStore.reduxStore = store;

ReactDOM.render(
  <AppStateProvider.Provider value={legacyApp}>
    <ReduxProvider store={store}>
      <App />
    </ReduxProvider>
  </AppStateProvider.Provider>,
  document.querySelector("#app")
);
forceRenderStyles();
