import * as React from "react";
import { stylesheet } from "typestyle";
import { gridBaseline } from "~base/styles";

const styles = stylesheet({
  readableColumn: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
  },
});

export type Props = {
  maxWidth?: number;
};

const DEFAULT_WIDTH = gridBaseline * 120;

export const ReadableColumn: React.FunctionComponent<Props> = props => (
  <div className={styles.readableColumn}>
    <div style={{ flexBasis: props.maxWidth || DEFAULT_WIDTH, flexShrink: 1 }}>
      {props.children}
    </div>
  </div>
);
