import { Vec2 } from "../../base/vec2";
import * as React from "react";

type Props = {
  start: Vec2.T;
  end: Vec2.T;
};

export const SelectionMarquee = ({ start, end }: Props) => {
  const minX = Math.min(start.x, end.x);
  const minY = Math.min(start.y, end.y);
  const width = Math.abs(start.x - end.x);
  const height = Math.abs(start.y - end.y);
  return (
    <rect
      x={minX}
      y={minY}
      width={width}
      height={height}
      className="svg-str-col-fg-a svg-fill-col-fg-a svg-fill-opacity-20"
    />
  );
};
