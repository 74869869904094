import classnames from "classnames";
import * as React from "react";
import { IconCommonProps } from "~/components/Icons";
import { Theme } from "~/themes/theme";
import { stylesheet } from "typestyle";
import { withStyles } from "~/themes/provider";
import { gridBaseline } from "~/base/styles";
import { TextNormal } from "~/base/typography";
import { Stack } from "~/view/layout/Stack";

export const buttonDefaultStyles = {
  border: 0,
  padding: 0,
  margin: 0,
  cursor: "pointer",
  backgroundColor: "transparent",
};

type Props = {
  active?: boolean;
  Icon?: React.ComponentType<IconCommonProps>;
  onClick?(e: { clientX: number; clientY: number }): void;
  onMouseDown?(e: { button: number; clientX: number; clientY: number }): void;
  onContextMenu?(e: {
    preventDefault(): void;
    stopPropagation(): void;
    clientX: number;
    clientY: number;
  }): void;
  className?: string;
  noPadding?: boolean;
  style?: "normal" | "borderless";
};

const styles = (theme: Theme) =>
  stylesheet({
    base: {
      ...buttonDefaultStyles,
      boxSizing: "border-box",
      backgroundColor: theme.backgroundA,
      cursor: "pointer",
      $nest: {
        "&:hover": {
          backgroundColor: theme.backgroundC,
        },
      },
    },
    padding: {
      padding: `${gridBaseline * 1.5}px ${gridBaseline * 1.5}px`,
    },
    noPadding: {
      padding: 0,
    },
    border: {
      borderWidth: 1,
      borderColor: theme.foregroundB,
    },
    borderless: {
      border: "none",
    },
    active: {
      fontWeight: "bold",
    },
    label: {
      padding: `${gridBaseline / 4}px ${gridBaseline / 2}px`,
    },
    tabButton: {
      ...buttonDefaultStyles,
      padding: gridBaseline * 2,
    },
  });

export const Button: React.ComponentType<Props> = withStyles(styles)(
  ({
    children,
    Icon,
    onClick,
    onMouseDown,
    onContextMenu,
    noPadding,
    className,
    styles,
  }) => {
    return (
      <button
        className={classnames(
          className,
          styles.base,
          noPadding === true ? styles.noPadding : styles.padding
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
        onContextMenu={onContextMenu}
      >
        {Icon ? <Icon size={gridBaseline * 2} /> : null}
        {children && (
          <span className={styles.label}>
            <TextNormal>{children}</TextNormal>
          </span>
        )}
      </button>
    );
  }
);

type PlainButtonProps = {
  Icon?: React.ComponentType<IconCommonProps>;
  active?: boolean;
  onClick?(): void;
  type?: "submit" | "reset" | "button";
  disabled?: boolean;
};

const plainButtonStyles = (theme: Theme) =>
  stylesheet({
    plainButton: {
      ...buttonDefaultStyles,
      padding: gridBaseline,
      backgroundColor: theme.backgroundE,
      border: `1px solid ${theme.accentA}`,
      borderRadius: 4,
    },
    inactive: {
      padding: gridBaseline,
      $nest: {
        "&:hover": {
          backgroundColor: theme.backgroundG,
        },
      },
    },
    pressing: {
      padding: gridBaseline,
      backgroundColor: theme.backgroundA,
    },
    active: {
      backgroundColor: theme.backgroundG,
      fontWeight: "bold",
      padding: gridBaseline - 2,
      border: `2px solid ${theme.foregroundA}`,
    },
  });

/**
 * A button that appears in a vertical list of buttons
 */
export const PlainButton: React.FunctionComponent<PlainButtonProps> = withStyles(
  plainButtonStyles
)(props => {
  const [isDown, setIsDown] = React.useState(false);
  const setDown = () => setIsDown(true);
  const setUp = () => setIsDown(false);

  let style = props.styles.inactive;
  if (props.active) {
    style = props.styles.active;
  } else if (isDown) {
    style = props.styles.pressing;
  }

  return (
    <button
      type={props.type ? props.type : "button"}
      onClick={props.onClick}
      onMouseDown={setDown}
      disabled={props.disabled}
      onMouseUp={setUp}
      onMouseOut={setUp}
      onTouchStart={props.onClick}
      className={classnames(props.styles.plainButton, style)}
    >
      <Stack direction="row" align="center" spacing={gridBaseline}>
        {props.Icon && <props.Icon size={gridBaseline * 3} />}
        <TextNormal>{props.children}</TextNormal>
      </Stack>
    </button>
  );
});

type TabButtonProps = {
  Icon: React.ComponentType<IconCommonProps>;
  active?: boolean;
  onClick(): void;
  title: string;
};

const tabButtonStyles = (theme: Theme) =>
  stylesheet({
    tabButton: {
      ...buttonDefaultStyles,
      padding: gridBaseline,
      height: gridBaseline * 6,
    },
    inactive: {
      $nest: {
        "&:hover": {
          backgroundColor: theme.backgroundG,
        },
      },
    },
    active: {
      backgroundColor: theme.backgroundC,
    },
  });

export const TabButton: React.FunctionComponent<TabButtonProps> = withStyles(
  tabButtonStyles
)(props => (
  <button
    onClick={props.onClick}
    onTouchStart={props.onClick}
    title={props.title}
    className={classnames(
      props.styles.tabButton,
      props.active ? props.styles.active : props.styles.inactive
    )}
  >
    <props.Icon size={gridBaseline * 4} />
  </button>
));

type ListButtonProps = {
  Icon: React.ComponentType<IconCommonProps>;
  active?: boolean;
  onClick(): void;
};

const listButtonStyles = (theme: Theme) =>
  stylesheet({
    listButton: {
      ...buttonDefaultStyles,
      backgroundColor: theme.backgroundE,
      width: "100%",
      borderRadius: 4,
    },
    inactive: {
      padding: gridBaseline,
      $nest: {
        "&:hover": {
          backgroundColor: theme.backgroundG,
        },
      },
    },
    pressing: {
      padding: gridBaseline,
      backgroundColor: theme.backgroundA,
    },
    active: {
      backgroundColor: theme.backgroundG,
      fontWeight: "bold",
      padding: gridBaseline - 2,
      border: `2px solid ${theme.foregroundA}`,
    },
  });

/**
 * A button that appears in a vertical list of buttons
 */
export const ListButton: React.FunctionComponent<ListButtonProps> = withStyles(
  listButtonStyles
)(props => {
  const [isDown, setIsDown] = React.useState(false);
  const setDown = () => setIsDown(true);
  const setUp = () => setIsDown(false);

  let style = props.styles.inactive;
  if (props.active) {
    style = props.styles.active;
  } else if (isDown) {
    style = props.styles.pressing;
  }

  return (
    <button
      onClick={props.onClick}
      onMouseDown={setDown}
      onMouseUp={setUp}
      onMouseOut={setUp}
      onTouchStart={props.onClick}
      className={classnames(props.styles.listButton, style)}
    >
      <Stack direction="row" align="center" spacing={gridBaseline}>
        <props.Icon size={gridBaseline * 3} />
        <TextNormal>{props.children}</TextNormal>
      </Stack>
    </button>
  );
});
