import * as React from "react";
import { Button } from "~/view/ui/button/Button";
import { stylesheet } from "typestyle";
import { CancelIcon } from "~components/Icons";

type Props = {
  type: "error" | "warning" | "notice";
  onClickClose(): void;
};

const styles = stylesheet({
  container: {
    top: 0,
    left: 0,
  },
});

export const Toast: React.ComponentType<Props> = props => (
  <div
    className="margin-2 shadow text-normal pointer-events-auto bg-col-bg-a border-1 border-col-fg-a"
    style={{ width: 300 }}
  >
    <div className="flex-container">
      <div className="pad-3 flex-grow-1">{props.children}</div>
      <Button
        onClick={props.onClickClose}
        Icon={CancelIcon}
        style="borderless"
      />
    </div>
  </div>
);

export const ToastContainer: React.ComponentType = props => (
  <div
    className={`border-box posAbsolute flex-column flex-align-end fillDimensions pointer-events-none ${styles.container}`}
  >
    {props.children}
  </div>
);
