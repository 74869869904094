import * as React from "react";
import { observer } from "mobx-react-lite";
import { FuzzyFinderActions } from "../../state/ui/fuzzy_finder";
import {
  FuzzyFinderStore,
  FuzzyPart,
  SearchResult,
} from "../../state/data/ui/fuzzy_finder";
import classNames = require("classnames");

type Props<K> = {
  maxHeight: number | undefined;
  placeholderText: string;
  store: FuzzyFinderStore<K>;
  searchFunc: (query: string) => SearchResult<K>[];
  onSelect(key: K, query: string): void;
  autoFocus?: boolean;
};

const FuzzyParts = (props: { fuzzyParts: FuzzyPart[] }) => {
  return (
    <>
      {props.fuzzyParts.map((fuzzyPart, i) => (
        <span
          key={i}
          className={classNames({
            ["text-underline text-col-acc-b"]: fuzzyPart.t === "match",
          })}
        >
          {fuzzyPart.text}
        </span>
      ))}
    </>
  );
};

export const FuzzyFinder = observer(<K extends unknown>(props: Props<K>) => {
  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    FuzzyFinderActions.onChangeQuery(
      props.store,
      props.searchFunc,
      e.currentTarget.value
    );
  };
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      FuzzyFinderActions.onSelectCurrent(props.store, props.onSelect);
    } else if (e.key === "ArrowDown") {
      e.preventDefault();
      FuzzyFinderActions.onSelectNext(props.store);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      FuzzyFinderActions.onSelectPrevious(props.store);
    }
  };

  return (
    <div style={{ maxHeight: props.maxHeight }} className="flex-column">
      <div className="flex-container">
        <input
          type="text"
          value={props.store.query.get()}
          autoFocus={props.autoFocus}
          className="flex-grow-1 border-1 border-col-bg-c bg-col-bg-a pad-3 text-normal"
          placeholder={props.placeholderText}
          onChange={onChange}
          onKeyDown={onKeyDown}
        />
      </div>
      {props.store.options.length >= 1 && (
        <div className="overflow-auto-y">
          {props.store.options.map((opt, i) => (
            <button
              className={classNames(
                "bg-col-bg-a block border-col-fg-a border-none cursor-pointer text-normal pad-1 width-100pct text-align-left",
                {
                  ["bg-col-acc-a"]: i === props.store.selectedIndex.get(),
                }
              )}
              onClick={() =>
                FuzzyFinderActions.onSelectIndex(props.store, props.onSelect, i)
              }
            >
              <FuzzyParts fuzzyParts={opt.matchOutput} />
            </button>
          ))}
        </div>
      )}
    </div>
  );
});
