import { ListButton } from "./button/Button";
import * as React from "react";
import {
  HideIcon,
  PlayIcon,
  CompleteIcon,
  CancelIcon,
} from "../../components/Icons";
import { Stack } from "~view/layout/Stack";

type Props = {
  onClickComplete?(): void;
  onClickInProgress?(): void;
  onClickHide?(): void;
  onClickDelete?(): void;
};

export const BulkActions: React.ComponentType<Props> = props => {
  return (
    <Stack direction="column">
      {props.onClickHide && (
        <ListButton active={false} Icon={HideIcon} onClick={props.onClickHide}>
          Hide
        </ListButton>
      )}
      {props.onClickInProgress && (
        <ListButton
          active={false}
          Icon={PlayIcon}
          onClick={props.onClickInProgress}
        >
          In Progress
        </ListButton>
      )}
      {props.onClickComplete && (
        <ListButton
          active={false}
          Icon={CompleteIcon}
          onClick={props.onClickComplete}
        >
          Complete
        </ListButton>
      )}
      {props.onClickDelete && (
        <ListButton
          active={false}
          Icon={CancelIcon}
          onClick={props.onClickDelete}
        >
          Delete
        </ListButton>
      )}
    </Stack>
  );
};
