import * as React from "react";
import { IconCommonProps } from "~components/Icons";
import { Theme } from "~themes/theme";
import { stylesheet } from "typestyle";
import { buttonDefaultStyles } from "./Button";
import { gridBaseline } from "~base/styles";
import { withStyles } from "~themes/provider";
import classnames from "classnames";

type ToolbarButtonProps = {
  Icon: React.ComponentType<IconCommonProps>;
  active?: boolean;
  onClick(): void;
};

const toolbarButtonStyles = (theme: Theme) =>
  stylesheet({
    toolbarButton: {
      ...buttonDefaultStyles,
      padding: gridBaseline,
      height: gridBaseline * 6,
    },
    inactive: {
      $nest: {
        "&:hover": {
          backgroundColor: theme.backgroundF,
        },
      },
    },
    active: {
      backgroundColor: theme.backgroundG,
    },
  });

export const ToolbarButton: React.FunctionComponent<ToolbarButtonProps> = withStyles(
  toolbarButtonStyles
)(props => (
  <button
    onClick={props.onClick}
    onTouchStart={props.onClick}
    className={classnames(
      props.styles.toolbarButton,
      props.active ? props.styles.active : props.styles.inactive
    )}
  >
    <props.Icon size={gridBaseline * 4} />
  </button>
));
