import * as React from "react";
import { Button } from "../view/ui/button/Button";

type Props = {
  note: string;
};

export class ErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo) {
    // Log error somewhere
    console.error(
      `ErrorBoundary caught error in ${this.props.note}`,
      error,
      errorInfo.componentStack
    );
  }

  render() {
    if (this.state.hasError) {
      const onClickReset = () => {
        this.setState({ hasError: false });
      };
      return (
        <div>
          <h1 className="text-title">Something went wrong.</h1>
          <Button onClick={onClickReset}>Reset</Button>
        </div>
      );
    }

    return this.props.children;
  }
}
