import {
  generateId,
  GraphViewId,
  TaskId,
} from "../../persistence/log/identifiers";
import { action, runInAction } from "mobx";
import { AppStore } from "../data";
import { GraphViewActions } from "./graph_view";
import { DomainActions } from "../domain";
import { expect } from "../../util";
import { graphViewRename } from "../../persistence/log/actions";
import { DomainAction } from "../../persistence/log/action_definitions";
import { ActionResult } from "../../persistence/log/errors";
import { SidePanelState } from "../data/ui";
import { SfxActions } from "~state";
import { SfxState } from "~sfx/sfx";
import { ActionType } from "~store/action-types";

type History = {
  pushState: (state: any, title: string, path: string) => void;
};

type Opts = {
  history?: History;
};

export class UiActions {
  opts: Opts;
  store: AppStore;
  graphView: GraphViewActions;
  domain: DomainActions;
  sfx: SfxActions;

  constructor(
    store: AppStore,
    domainActions: DomainActions,
    opts: Opts,
    saveAction: (action: DomainAction) => ActionResult,
    sfxActions: SfxActions
  ) {
    this.store = store;
    this.opts = opts;
    this.domain = domainActions;
    this.sfx = sfxActions;
    this.graphView = new GraphViewActions(
      store,
      domainActions,
      saveAction,
      sfxActions
    );
  }

  showHome = action(() => {
    this.store.reduxStore.dispatch({ type: ActionType.UI_PATH_SET, path: "/" });
  });

  showTask(taskId: TaskId) {
    this.store.reduxStore.dispatch({
      type: ActionType.UI_PATH_SET,
      path: `/task/${taskId}`,
    });
  }

  showGraphView(graphViewId: GraphViewId) {
    this.store.reduxStore.dispatch({
      type: ActionType.UI_PATH_SET,
      path: `/graph/${graphViewId}`,
    });
  }

  graphViewNameEdit(graphViewId: GraphViewId) {
    const graphView = expect(
      this.store.domain.graphViews.items.get(graphViewId)
    );
    this.store.ui.state.set({
      t: "view_name_editing",
      graphViewId,
      value: graphView.label.get(),
    });
  }

  graphViewNameChange(newValue: string) {
    const state = this.store.ui.state.get();
    if (state.t !== "view_name_editing") {
      return;
    }
    this.store.ui.state.set({ ...state, value: newValue });
  }

  graphViewNameSave() {
    const state = this.store.ui.state.get();
    if (state.t !== "view_name_editing") {
      return;
    }

    this.domain.saveAction(graphViewRename(state.graphViewId, state.value));
    this.resetState();
  }

  toggleSidePanel(panel: SidePanelState["t"]) {
    const currentPanel = this.store.ui.sidePanel.get();
    if (currentPanel.t === panel) {
      // Selected the same panel, so hide it
      this.store.ui.sidePanel.set({ t: "collapsed" });
    } else {
      this.store.ui.sidePanel.set({ t: panel });
    }
  }

  notify = action((text: string) => {
    this.sfx.play(SfxState.Sound.INFO);
    this.store.ui.notifications.push({ id: generateId(), text });
  });

  removeNotification = action((id: string) => {
    console.log("removing", id);
    this.store.ui.notifications.replace(
      this.store.ui.notifications.filter(n => n.id !== id)
    );
  });

  resetState() {
    runInAction(() => {
      this.store.ui.state.set({ t: "none" });
    });
  }
}
