import * as React from "react";
import { useSelector, useDispatch } from "~store/store";
import { State } from "~store/reducer";
import { BaseModal } from "./BaseModal";
import { UiModalActions, SimpleModal } from "~store/ui/modal/actions";
import { Button } from "~view/ui/button/Button";
import { Stack } from "~view/layout/Stack";
import { TextNormal, Heading1 } from "~base/typography";
import { stylesheet } from "typestyle";
import { gridBaseline } from "~base/styles";

const getCurrentModal = (state: State) => state.ui.modal.currentModal;

const styles = stylesheet({
  simpleModal: {
    padding: gridBaseline * 2,
  },
});

export const ActionButton: React.FunctionComponent<{
  button: SimpleModal.Button;
}> = props => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UiModalActions.hide({}));
    if (props.button.action != null) {
      dispatch(props.button.action);
    }
  };

  return <Button onClick={onClick}>{props.button.label}</Button>;
};

export const SimpleModalComponent: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getCurrentModal);
  if (modal == null || modal.t !== UiModalActions.ModalType.SIMPLE) {
    return null;
  }

  const onClickOutside = () => {
    dispatch(UiModalActions.hide({}));
    if (modal.defaultAction != null) {
      dispatch(modal.defaultAction);
    }
  };

  return (
    <BaseModal onClickOutside={onClickOutside} maxWidth={gridBaseline * 60}>
      <div className={styles.simpleModal}>
        <Stack direction="column" spacing={gridBaseline}>
          <Heading1>{modal.heading}</Heading1>
          <TextNormal>{modal.text}</TextNormal>
          <Stack direction="row">
            {modal.buttons.map((b, i) => (
              <ActionButton button={b} key={i} />
            ))}
          </Stack>
        </Stack>
      </div>
    </BaseModal>
  );
};
