import { ExportJsonV1 } from "./schema_v1";
import { DomainStore } from "../state/data/domain";

export function getExportContent(domainStore: DomainStore) {
  const exportObj: ExportJsonV1 = {
    version: "v1",
    tasks: [],
    dependencies: [],
    graphViews: [],
  };
  for (const task of domainStore.tasks.items.values()) {
    exportObj.tasks.push({
      id: task.id,
      title: task.title.get(),
      description: task.description.get(),
      status: task.status.get(),
    });
  }
  for (const dep of domainStore.dependencies.items.values()) {
    exportObj.dependencies.push({
      left: dep.left,
      right: dep.right,
    });
  }
  for (const [graphViewId, graphView] of domainStore.graphViews.items) {
    const positions = [];
    for (const [taskId, pos] of graphView.taskPositions) {
      positions.push({ taskId, pos });
    }
    exportObj.graphViews.push({
      id: graphViewId,
      label: graphView.label.get(),
      taskPositions: positions,
    });
  }

  return JSON.stringify(exportObj);
}

export function doExport(domainStore: DomainStore) {
  const content = getExportContent(domainStore);
  const blob = new Blob([content], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "export.taskdeps.json";
  a.click();
  window.URL.revokeObjectURL(url);
}
