import { AppActions } from "../state";
import { TaskView } from "./TaskView";
import * as React from "react";
import { observer } from "mobx-react-lite";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { graphViewTaskHide, setTaskStatus } from "../persistence/log/actions";
import { BulkActions } from "./ui/BulkActions";
import { TaskId } from "../persistence/log/identifiers";
import { useSelector } from "~store/store";
import { Text } from "~base/typography";
import { gridBaseline } from "~base/styles";

const NoTasks = () => (
  <div style={{ padding: gridBaseline }}>
    <Text level="p" muted={true}>
      Select a task to see information
    </Text>
  </div>
);

const SingleTask = observer((props: { app: AppActions }) => {
  const { selection } = props.app.store.ui;

  const onChooseTask = (task: TaskId) => {
    selection.replace([task]);
  };

  const view = useSelector(state => state.ui.view);
  const graphViewId = view && view.view === "graph" && view.graphViewId;

  return (
    <TaskView
      taskId={selection[0]}
      onChooseTask={onChooseTask}
      graphViewId={graphViewId || undefined}
    />
  );
});

const MultipleTasks = observer((props: { app: AppActions }) => {
  const { selection } = props.app.store.ui;

  const view = useSelector(state => state.ui.view);
  const graphViewId = view && view.view === "graph" && view.graphViewId;

  const onClickComplete = () => {
    selection.forEach(id =>
      props.app.domain.saveAction(setTaskStatus(id, "complete"))
    );
  };
  const onClickInProgress = () => {
    selection.forEach(id =>
      props.app.domain.saveAction(setTaskStatus(id, "in-progress"))
    );
  };
  const onClickHide = graphViewId
    ? () => {
        props.app.domain.saveAction(graphViewTaskHide(graphViewId, selection));
      }
    : undefined;
  return (
    <div className="pad-3">
      <div>{selection.length} tasks selected</div>
      <BulkActions
        onClickComplete={onClickComplete}
        onClickInProgress={onClickInProgress}
        onClickHide={onClickHide}
      />
    </div>
  );
});

export const ContextSidebar = observer((props: { app: AppActions }) => {
  const { selection } = props.app.store.ui;
  if (selection.length < 1) {
    return <NoTasks />;
  }

  return (
    <ErrorBoundary note="Sidebar">
      <div className="overflow-auto-y text-normal">
        {selection.length > 1 ? (
          <MultipleTasks app={props.app} />
        ) : (
          <SingleTask app={props.app} />
        )}
      </div>
    </ErrorBoundary>
  );
});
