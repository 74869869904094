import { Vec2 } from "../../base/vec2";
import * as React from "react";

const FONT_SIZE = 14;
const HEIGHT = 20;
const WIDTH = 30;
const TEXT_MARGIN = (HEIGHT - FONT_SIZE) / 2;

export const HiddenDepIndicator = (props: { pos: Vec2.T; count: number }) => {
  return (
    <>
      <rect
        x={props.pos.x}
        y={props.pos.y + 8}
        width={30}
        height={20}
        rx={5}
        className="svg-fill-col-acc-a"
      />
      <text
        x={props.pos.x + WIDTH / 2}
        y={props.pos.y + HEIGHT - TEXT_MARGIN + 6}
        textAnchor="middle"
        fontSize={FONT_SIZE}
        className="svg-fill-col-fg-a"
      >
        +{props.count}
      </text>
    </>
  );
};
