import { Result } from "../../base/result";

export const enum ActionErrorTag {
  TASK_DEPENDENCY_EXISTS,
  TASK_DEPENDENCY_CYCLE,
  TASK_DEPENDS_ON_SELF,
  TASK_NOT_FOUND,
  TASK_NAME_EMPTY,
}

export type ActionResult = Result.T<void, ActionErrorTag>;

export const ErrorMessages: { [k in ActionErrorTag]: string } = {
  [ActionErrorTag.TASK_DEPENDENCY_EXISTS]: "This dependency already exists",
  [ActionErrorTag.TASK_DEPENDENCY_CYCLE]:
    "Adding this task as a dependency would create a cycle",
  [ActionErrorTag.TASK_DEPENDS_ON_SELF]: "A task can't depend on itself",
  [ActionErrorTag.TASK_NOT_FOUND]: "The selected task couldn't be found",
  [ActionErrorTag.TASK_NAME_EMPTY]: "Please enter a name for the task",
};
