import { GraphViewId, TaskId } from "../persistence/log/identifiers";
import * as React from "react";
import { TaskList } from "./TaskList";
import { observer } from "mobx-react-lite";
import { TaskLookup } from "./TaskLookup";
import * as Actions from "../persistence/log/actions";
import { deleteTaskDependency } from "../persistence/log/actions";
import { DomainAction } from "../persistence/log/action_definitions";
import { ListButton } from "./ui/button/Button";
import {
  CancelIcon,
  HideIcon,
  PlayIcon,
  CompleteIcon,
} from "../components/Icons";
import { Stack } from "./layout/Stack";
import { gridBaseline } from "../base/styles";
import { stylesheet } from "typestyle";
import { useAppState } from "~app_provider";
import { Result } from "~base/result";
import { SfxState } from "~sfx/sfx";

type DescriptionEditState =
  | { editing: false }
  | { editing: true; body: string };

const styles = stylesheet({
  titleInput: {
    backgroundColor: "transparent",
  },
});

export const TaskView = observer(
  ({
    taskId,
    onChooseTask,
    graphViewId,
  }: {
    taskId: TaskId;
    onChooseTask: (task: TaskId) => void;
    graphViewId?: GraphViewId;
  }) => {
    const app = useAppState();
    const [description, setDescription] = React.useState<DescriptionEditState>({
      editing: false,
    });

    const currentTask = app.domain.tasks.findById(taskId);
    if (currentTask == null) {
      return null;
    }
    const otherTasks = app.domain.getDependenciesForTask(taskId);

    const saveAction = (action: DomainAction) => app.saveAction(action);

    const onCreatePrerequisite = (description: string) =>
      saveAction(Actions.createTask(description, undefined, taskId));

    const onCreateDependant = (description: string) =>
      saveAction(Actions.createTask(description, taskId, undefined));

    const onChangeTaskTitle = (title: string) =>
      saveAction(Actions.renameTask(taskId, title));

    const onChoosePrerequisite = (prereqId: TaskId) =>
      saveAction(Actions.createTaskDependency(taskId, prereqId));

    const onChooseDependant = (dependantId: TaskId) =>
      saveAction(Actions.createTaskDependency(dependantId, taskId));

    const onTaskDelete = () => {
      if (!currentTask) {
        return;
      }

      let result = saveAction(Actions.deleteTask(taskId));

      if (Result.isOk(result)) {
        app.sfx.play(SfxState.Sound.CANCEL);
      }
    };

    const onTaskHide = () => {
      if (!currentTask || !graphViewId) {
        return;
      }

      let result = saveAction(Actions.graphViewTaskHide(graphViewId, [taskId]));

      if (Result.isOk(result)) {
        app.sfx.play(SfxState.Sound.CANCEL);
      }
    };

    const onToggleStatus = (status: "in-progress" | "complete") => () => {
      if (!currentTask) {
        return;
      }

      const currentStatus = currentTask.status.get();
      const newStatus = currentStatus === status ? "none" : status;

      let result = saveAction(Actions.setTaskStatus(taskId, newStatus));

      if (Result.isOk(result)) {
        newStatus === "complete" && app.sfx.play(SfxState.Sound.COMPLETE);
        newStatus === "none" && app.sfx.play(SfxState.Sound.OFF);
        newStatus === "in-progress" && app.sfx.play(SfxState.Sound.ON);
      }
    };

    const onStartEditingDescription = (
      e: React.MouseEvent<HTMLAnchorElement>
    ) => {
      e.preventDefault();

      setDescription({ editing: true, body: currentTask!.description.get() });
    };
    const onFinishEditingDescription = () => {
      if (description.editing) {
        saveAction(
          Actions.updateTaskDescription(taskId, description.body.trim())
        );
      }

      setDescription({ editing: false });
    };
    const onChangeDescription = (e: React.FormEvent<HTMLTextAreaElement>) => {
      setDescription({ editing: true, body: e.currentTarget.value });
    };

    const onRemoveBlocker = (blockerId: TaskId) => {
      app.domain.saveAction(deleteTaskDependency(blockerId, taskId));
    };

    const onRemoveDependant = (depId: TaskId) => {
      app.domain.saveAction(deleteTaskDependency(taskId, depId));
    };

    const currentTaskStatus = currentTask.status.get();

    return (
      <div className="pad-3">
        <Stack direction="column" spacing={gridBaseline * 2}>
          <>
            <div>
              <Stack direction="column" spacing={gridBaseline}>
                <input
                  className={`text-title border-none width-100pct ${styles.titleInput}`}
                  value={currentTask.title.get()}
                  onChange={e => onChangeTaskTitle(e.target.value)}
                />
                <div className="flex-container flex-grow-1">
                  {description.editing ? (
                    <textarea
                      className="text-normal border-1 border-col-fg-a flex-grow-1 bg-col-bg-a"
                      onChange={onChangeDescription}
                      autoFocus={true}
                      onBlur={onFinishEditingDescription}
                      value={description.body}
                    />
                  ) : (
                    <a
                      href="#"
                      className="block text-normal flex-grow-1"
                      onClick={onStartEditingDescription}
                    >
                      {currentTask.description.get() || "Edit description"}
                    </a>
                  )}
                </div>
                <Stack direction="column">
                  <ListButton
                    Icon={PlayIcon}
                    active={currentTaskStatus === "in-progress"}
                    onClick={onToggleStatus("in-progress")}
                  >
                    In Progress
                  </ListButton>
                  <ListButton
                    Icon={CompleteIcon}
                    active={currentTaskStatus === "complete"}
                    onClick={onToggleStatus("complete")}
                  >
                    Complete
                  </ListButton>
                  <ListButton Icon={CancelIcon} onClick={onTaskDelete}>
                    Delete
                  </ListButton>
                  {graphViewId && (
                    <ListButton
                      active={false}
                      Icon={HideIcon}
                      onClick={onTaskHide}
                    >
                      Hide
                    </ListButton>
                  )}
                </Stack>
              </Stack>
            </div>
          </>
          <div>
            <Stack direction="column">
              <h1 className="text-title">Blockers</h1>
              <TaskList
                tasks={app.domain.tasks.findByIds(otherTasks.dependsOn)}
                onChooseTask={onChooseTask}
                onRemoveTask={onRemoveBlocker}
                unblocked={app.store.domain.tasks.unblocked}
              />
              <TaskLookup
                domainActions={app.domain}
                finderStore={app.store.ui.sidebar.blockerFinder}
                onSelectTask={onChoosePrerequisite}
                onCreateTask={onCreatePrerequisite}
              />
            </Stack>
          </div>
          <div>
            <Stack direction="column">
              <h1 className="text-title">Dependants</h1>
              <TaskList
                tasks={app.domain.tasks.findByIds(otherTasks.dependedOnBy)}
                onChooseTask={onChooseTask}
                onRemoveTask={onRemoveDependant}
                unblocked={app.store.domain.tasks.unblocked}
              />
              <TaskLookup
                domainActions={app.domain}
                finderStore={app.store.ui.sidebar.dependencyFinder}
                onSelectTask={onChooseDependant}
                onCreateTask={onCreateDependant}
              />
            </Stack>
          </div>
        </Stack>
      </div>
    );
  }
);
