import { ActionType } from "~store/action-types";
import { actionCreator } from "~lib/action-creators";
import { ExportJson } from "~export/schema";

export namespace ProjectActions {
  export type ProjectReference =
    | { t: "from-export"; data: ExportJson }
    | { t: "blank" }
    | { t: "example" };

  export type Load = {
    type: ActionType.PROJECT_LOAD;
    project: ProjectReference;
    closeConfirmed: boolean;
  };
  export type OpenFromFile = { type: ActionType.PROJECT_OPEN_FROM_FILE };
  export type Action = Load | OpenFromFile;

  export const load = actionCreator<Load, ActionType>(ActionType.PROJECT_LOAD);
  export const openFromFile = actionCreator<OpenFromFile, ActionType>(
    ActionType.PROJECT_OPEN_FROM_FILE
  );
}

