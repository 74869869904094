import * as React from "react";
import { useSelector, useDispatch } from "~store/store";
import { State } from "~store/reducer";
import { BaseModal } from "./BaseModal";
import { UiModalActions, SimpleModal } from "~store/ui/modal/actions";
import { PlainButton } from "~view/ui/button/Button";
import { Stack } from "~view/layout/Stack";
import { TextNormal, Heading1 } from "~base/typography";
import { stylesheet } from "typestyle";
import { gridBaseline } from "~base/styles";
import gifCreate from "~assets/example-create.gif";
import gifCreate2 from "~assets/example-create2.gif";
import gifConnect from "~assets/example-connect.gif";
import gifHide from "~assets/example-hide.gif";

const getCurrentModal = (state: State) => state.ui.modal.currentModal;

const styles = stylesheet({
  tutorialModal: {
    padding: gridBaseline * 2,
  },
  image: {
    width: "100%",
    padding: `${gridBaseline * 2}px ${gridBaseline * 4}px`,
  },
});

export const ActionButton: React.FunctionComponent<{
  button: SimpleModal.Button;
}> = props => {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(UiModalActions.hide({}));
    if (props.button.action != null) {
      dispatch(props.button.action);
    }
  };

  return <PlainButton onClick={onClick}>{props.button.label}</PlainButton>;
};

export const TutorialModalComponent: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const modal = useSelector(getCurrentModal);
  if (modal == null) {
    return null;
  }

  const onClose = () => {
    dispatch(UiModalActions.hide({}));
  };

  return (
    <BaseModal onClickOutside={onClose} maxWidth={gridBaseline * 110}>
      <div className={styles.tutorialModal}>
        <Content onClose={onClose} />
      </div>
    </BaseModal>
  );
};

const Screenshot = (props: { src: string }) => (
  <img className={styles.image} src={props.src} />
);

const Content = (props: { onClose: () => void }) => {
  return (
    <Stack direction="column" spacing={gridBaseline * 2}>
      <Stack direction="column">
        <Heading1>Getting started</Heading1>
        <TextNormal>
          <p>Click in an empty space on the graph to create a new task.</p>
        </TextNormal>
        <Screenshot src={gifCreate} />
        <TextNormal>
          <p>Drag the end of a task to another task to set up a dependency.</p>
        </TextNormal>
        <Screenshot src={gifConnect} />
        <TextNormal>
          <p>
            Dragging to an empty space is a quick way to create a task and set
            up a dependency in one click.
          </p>
        </TextNormal>
        <Screenshot src={gifCreate2} />
        <TextNormal>
          <p>
            You can also hide tasks from a graph. When a task is hidden, it will
            still maintain its relationships to other tasks, so you can use this
            to organise your view.
          </p>
        </TextNormal>
        <TextNormal>
          <p>
            When a task has dependencies that aren't shown on that graph, you'll
            see a "+2" indicating the number of tasks. Clicking this will show
            the list of tasks, which can then be easily added back to the view.
          </p>
        </TextNormal>
        <Screenshot src={gifHide} />
        <Stack direction="row" justify="center">
          <PlainButton onClick={props.onClose}>Close Tutorial</PlainButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
