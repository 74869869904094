import * as React from "react";
import { useSelector } from "~store/store";
import { State } from "~store/reducer";
import { UiModalActions } from "~store/ui/modal/actions";
import { SimpleModalComponent } from "./SimpleModal";
import { TutorialModalComponent } from "./TutorialModal";

const getCurrentModal = (state: State) => state.ui.modal.currentModal;

export const Modal = () => {
  const modal = useSelector(getCurrentModal);
  if (modal == null) {
    return null;
  }

  switch (modal.t) {
    case UiModalActions.ModalType.SIMPLE:
      return <SimpleModalComponent />;
    case UiModalActions.ModalType.TUTORIAL:
      return <TutorialModalComponent />;
  }
};
