import * as React from "react";
import { observer } from "mobx-react-lite";
import { TaskId } from "../persistence/log/identifiers";
import classnames = require("classnames");
import { Task } from "../state/data/domain/tasks";
import { DependencyDeleteButton } from "./ui/DependencyDeleteButton";
import { ObservableSet } from "mobx";
import { Text } from "../base/typography";
import { stylesheet } from "typestyle";

type TaskSmallProps = {
  task: Task;
  isSelected?: boolean;
  isUnblocked: boolean;
  isGoal: boolean;
  onClick?: (task: TaskId) => void;
  onClickRemove?: (task: TaskId) => void;
  /**
   * Override the cursor (defaults to pointer)
   */
  cursor?: "grab";
};

const styles = stylesheet({
  cursorGrab: {
    cursor: "grab",
  },
  cursorPointer: {
    cursor: "pointer",
  },
});

export const TaskSmall = observer<TaskSmallProps>(
  ({
    task,
    isSelected,
    isGoal,
    isUnblocked,
    onClick,
    onClickRemove,
    cursor,
  }) => {
    const taskStatus = task.status.get();
    const isComplete = taskStatus === "complete";
    const inProgress = taskStatus === "in-progress";

    return (
      <div className="flex-container">
        <div
          className={classnames(
            {
              "bg-col-bg-a":
                task.status.get() === "complete" ||
                task.status.get() === "none",
              "bg-col-acc-a": task.status.get() === "in-progress",
              "outline-wide-1 outline-col-acc-b": isSelected,
            },
            cursor === "grab" ? styles.cursorGrab : styles.cursorPointer,
            "flex-grow-1 pad-3 border-1 border-col-fg-b hover-bg-col-bg-c"
          )}
          onClick={() => {
            onClick && onClick(task.id);
          }}
        >
          <Text
            level={isGoal ? "h2" : "p"}
            muted={(!inProgress && !isUnblocked) || isComplete}
            strikethrough={isComplete}
          >
            {task.title.get()}
          </Text>
        </div>
        {onClickRemove && (
          <DependencyDeleteButton onClick={() => onClickRemove(task.id)} />
        )}
      </div>
    );
  }
);

export const TaskList = observer(
  ({
    tasks,
    selected,
    unblocked,
    onChooseTask,
    onRemoveTask,
  }: {
    tasks: Task[];
    unblocked?: ObservableSet<TaskId>;
    selected?: ObservableSet<TaskId>;
    onChooseTask?: (task: TaskId) => void;
    onRemoveTask?: (task: TaskId) => void;
  }) => {
    if (tasks.length < 1) {
      return (
        <div>
          <Text level={"p"} muted={true}>
            No tasks here
          </Text>
        </div>
      );
    }
    return (
      <div>
        {tasks.map(t => (
          <TaskSmall
            key={t.id}
            task={t}
            isUnblocked={unblocked == null || unblocked.has(t.id)}
            isSelected={selected && selected.has(t.id)}
            isGoal={false}
            onClick={onChooseTask}
            onClickRemove={onRemoveTask}
          />
        ))}
      </div>
    );
  }
);
