import * as React from "react";
import { useAppState } from "~app_provider";
import { gridBaseline } from "~base/styles";
import { Heading1, Text } from "~base/typography";
import { Stack } from "~view/layout/Stack";
import { ListButton } from "~view/ui/button/Button";
import { DownloadIcon, CloudIcon } from "~components/Icons";
import { FocusBox } from "~view/layout/FocusBox";
import { useDispatch } from "~store/store";
import { ActionType } from "~store/action-types";

export const SaveScreen = () => {
  const app = useAppState();
  const dispatch = useDispatch();

  return (
    <FocusBox>
      <Stack direction="column" spacing={gridBaseline * 2}>
        <Heading1>Save Project</Heading1>
        <Stack direction="column">
          <ListButton
            Icon={DownloadIcon}
            onClick={() => {
              app.saveProjectLocally();
            }}
          >
            Save to disk
          </ListButton>
          <ListButton
            Icon={CloudIcon}
            onClick={() =>
              dispatch({
                type: ActionType.UI_PATH_SET,
                path: "/waitlist",
              })
            }
          >
            Save to cloud <Text level="pill">COMING SOON</Text>
          </ListButton>
        </Stack>
      </Stack>
    </FocusBox>
  );
};
