import * as React from "react";
import { Button } from "./button/Button";
import { DisconnectIcon } from "../../components/Icons";

type Props = {
  onClick(): void;
};

export const DependencyDeleteButton = (props: Props) => (
  <Button active={false} Icon={DisconnectIcon} onClick={props.onClick} />
);
