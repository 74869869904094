import { ExportJsonV1 } from "./schema_v1";

export const example: ExportJsonV1 = {
  version: "v1",
  tasks: [
    { id: "6jgbrg", title: "Saturday dinner", description: "", status: "none" },
    { id: "tewtjj", title: "Buy pasta", description: "", status: "complete" },
    { id: "rxaoms", title: "Buy tomatoes", description: "", status: "none" },
    {
      id: "a7xwea",
      title: "Visit farmers markets",
      description: "",
      status: "none",
    },
    {
      id: "atgxnb",
      title: "Visit supermarket",
      description: "",
      status: "complete",
    },
    { id: "0teuk", title: "Buy garlic", description: "", status: "none" },
    { id: "0hafcb", title: "Cook pasta", description: "", status: "none" },
    { id: "b43fte", title: "Cook sauce", description: "", status: "none" },
    { id: "c59bzl", title: "Buy candles", description: "", status: "complete" },
    { id: "5luxp", title: "Serve dinner", description: "", status: "none" },
    { id: "g2yac", title: "Light candles", description: "", status: "none" },
    {
      id: "ucksd",
      title: "Tidy up apartment",
      description: "",
      status: "none",
    },
    { id: "zner9", title: "Buy red wine", description: "", status: "complete" },
    { id: "ah9olf", title: "Buy carrots", description: "", status: "none" },
    { id: "tz7gvn", title: "Buy onions", description: "", status: "none" },
    { id: "7de52", title: "Buy parmesan", description: "", status: "complete" },
    {
      id: "69q9da",
      title: "Guests confirmed",
      description: "",
      status: "none",
    },
    { id: "xikpo9", title: "Call Julian", description: "", status: "complete" },
    { id: "lkqewt", title: "Call Kate", description: "", status: "complete" },
    { id: "72itoo", title: "Call Zach & Sam", description: "", status: "none" },
    { id: "l61al2", title: "Prep salad", description: "", status: "none" },
    { id: "245gue", title: "Buy olives", description: "", status: "none" },
    { id: "s9fcpf", title: "Buy lettuce", description: "", status: "none" },
  ],
  dependencies: [
    { left: "atgxnb", right: "tewtjj" },
    { left: "a7xwea", right: "rxaoms" },
    { left: "a7xwea", right: "0teuk" },
    { left: "tewtjj", right: "0hafcb" },
    { left: "0teuk", right: "b43fte" },
    { left: "rxaoms", right: "b43fte" },
    { left: "atgxnb", right: "c59bzl" },
    { left: "b43fte", right: "5luxp" },
    { left: "0hafcb", right: "5luxp" },
    { left: "5luxp", right: "6jgbrg" },
    { left: "ucksd", right: "6jgbrg" },
    { left: "g2yac", right: "6jgbrg" },
    { left: "c59bzl", right: "g2yac" },
    { left: "zner9", right: "b43fte" },
    { left: "atgxnb", right: "zner9" },
    { left: "a7xwea", right: "ah9olf" },
    { left: "ah9olf", right: "b43fte" },
    { left: "a7xwea", right: "tz7gvn" },
    { left: "tz7gvn", right: "b43fte" },
    { left: "atgxnb", right: "7de52" },
    { left: "7de52", right: "5luxp" },
    { left: "69q9da", right: "6jgbrg" },
    { left: "xikpo9", right: "69q9da" },
    { left: "lkqewt", right: "69q9da" },
    { left: "72itoo", right: "69q9da" },
    { left: "l61al2", right: "5luxp" },
    { left: "245gue", right: "l61al2" },
    { left: "a7xwea", right: "245gue" },
    { left: "s9fcpf", right: "l61al2" },
    { left: "a7xwea", right: "s9fcpf" },
    { left: "tz7gvn", right: "l61al2" },
    { left: "ah9olf", right: "l61al2" },
  ],
  graphViews: [
    {
      id: "dk2clo",
      label: "Dinner plan",
      taskPositions: [
        { taskId: "6jgbrg", pos: { x: 156, y: -189 } },
        { taskId: "tewtjj", pos: { x: -1144, y: 64 } },
        { taskId: "rxaoms", pos: { x: -1149, y: -209 } },
        { taskId: "a7xwea", pos: { x: -1592, y: -300 } },
        { taskId: "atgxnb", pos: { x: -1595, y: 41 } },
        { taskId: "0teuk", pos: { x: -1146, y: -152 } },
        { taskId: "0hafcb", pos: { x: -732, y: 63 } },
        { taskId: "b43fte", pos: { x: -724, y: -225 } },
        { taskId: "c59bzl", pos: { x: -1145, y: 121 } },
        { taskId: "5luxp", pos: { x: -316, y: -84 } },
        { taskId: "g2yac", pos: { x: -345, y: 117 } },
        { taskId: "ucksd", pos: { x: -338, y: -452 } },
        { taskId: "zner9", pos: { x: -1141, y: -49 } },
        { taskId: "ah9olf", pos: { x: -1151, y: -269 } },
        { taskId: "tz7gvn", pos: { x: -1153, y: -328 } },
        { taskId: "7de52", pos: { x: -1144, y: 8 } },
        { taskId: "69q9da", pos: { x: -340, y: -566 } },
        { taskId: "xikpo9", pos: { x: -768, y: -616 } },
        { taskId: "lkqewt", pos: { x: -767, y: -562 } },
        { taskId: "72itoo", pos: { x: -766, y: -507 } },
        { taskId: "l61al2", pos: { x: -723, y: -385 } },
        { taskId: "245gue", pos: { x: -1153, y: -441 } },
        { taskId: "s9fcpf", pos: { x: -1154, y: -385 } },
      ],
    },
  ],
};
