import * as React from "react";
import { Vec2 } from "../../base/vec2";

export const AddFirstTaskPrompt = (props: { pos: Vec2.T }) => (
  <text
    x={props.pos.x}
    y={props.pos.y}
    className="svg-fill-col-acc-a svg-text-anchor-mid text-title"
  >
    Click anywhere to add a task
  </text>
);
