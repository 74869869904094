import * as React from "react";
import { Vec2 } from "../base/vec2";

type Props = {
  className: string;
  onResize: (v: Vec2.T) => void;
};

export const Measurable: React.FunctionComponent<Props> = props => {
  const [prevWidth, setPrevWidth] = React.useState(1);
  const [prevHeight, setPrevHeight] = React.useState(1);

  const [ref, width, height] = useDimensions();

  React.useEffect(() => {
    if (prevWidth != width || prevHeight != height) {
      setPrevWidth(width);
      setPrevHeight(height);
      props.onResize({ x: width, y: height });
    }
  });

  return (
    <div ref={ref} className={props.className}>
      {props.children}
    </div>
  );
};

class Subscribers {
  subscribers: Set<() => void> = new Set();

  subscribe(f: () => void) {
    this.subscribers.add(f);
  }

  unsubscribe(f: () => void) {
    this.subscribers.delete(f);
  }

  notifyChange() {
    console.log("notifying " + this.subscribe.length + " subscribers");
    this.subscribers.forEach(f => f());
  }
}

const LayoutChangeContext = React.createContext(new Subscribers());

type UseDimensionsReturn = [(node: Element | null) => void, number, number];

export function useDimensions(): UseDimensionsReturn {
  const [width, updateWidth] = React.useState(1);
  const [height, updateHeight] = React.useState(1);
  const [node, setNode] = React.useState<Element | null>(null);

  const ref = React.useCallback((node: Element | null) => {
    setNode(node);
  }, []);
  const layoutChangeContext = React.useContext(LayoutChangeContext);

  React.useLayoutEffect(() => {
    const measure = () =>
      window.requestAnimationFrame(() => {
        if (node != null) {
          updateWidth(node.clientWidth);
          updateHeight(node.clientHeight);
        }
      });
    measure();

    window.addEventListener("resize", measure);
    layoutChangeContext.subscribe(measure);

    return () => {
      window.removeEventListener("resize", measure);
      layoutChangeContext.unsubscribe(measure);
    };
  }, [node]);

  return [ref, width, height];
}

export function useMeasurableContext() {
  return React.useContext(LayoutChangeContext);
}
