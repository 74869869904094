import { generateId, TaskId } from "../../persistence/log/identifiers";
import { expect } from "../../util";
import {
  ActionType,
  DomainAction,
} from "../../persistence/log/action_definitions";
import { DomainStore } from "../data/domain";
import { GraphViewActions } from "./graph_view";
import { TaskActions } from "./tasks";
import { Task } from "../data/domain/tasks";
import { Result } from "../../base/result";
import { ActionErrorTag, ActionResult } from "../../persistence/log/errors";
import { DependencyActions } from "./task_dependencies";
import { action } from "mobx";
import { Importer } from "~export/import";

export class DomainActions {
  store: DomainStore;
  tasks: TaskActions;
  dependencies: DependencyActions;
  graphViews: GraphViewActions;

  constructor(store: DomainStore) {
    this.store = store;
    this.tasks = new TaskActions(store);
    this.graphViews = new GraphViewActions(store);
    this.dependencies = new DependencyActions(store);
  }

  recover(store: DomainStore) {
    const logRaw = localStorage.getItem("log");

    if (logRaw) {
      console.log(`Current log size: ${Math.floor(logRaw.length / 1024)} KB`);
    }
    const log: DomainAction[] | null | undefined = logRaw && JSON.parse(logRaw);
    if (log) {
      this.store.reset();

      console.log(`- ${log.length} total actions`);
      const start = performance.now();
      log.forEach(action => {
        try {
          this.applyAction(action);
          store.eventLog.push(action);
        } catch (e) {
          console.error("Failed to load:", e);
          return store;
        }
      });
      console.log(`- Took ${performance.now() - start}ms to replay`);
      this.store.isOpen.set(true);
    }
  }

  loadFromFile() {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.multiple = false;
    fileInput.accept = ".twigtask.json,.json";
    fileInput.click();

    return new Promise(resolve => {
      fileInput.addEventListener("input", () => {
        if (fileInput.files != null && fileInput.files.length >= 1) {
          this.store.reset();
          resolve(Importer.importFile(fileInput.files[0], this));
        }
        resolve(undefined);
      });
    });
  }

  applyAction(action: DomainAction): Result.T<void, ActionErrorTag> {
    switch (action.type) {
      case ActionType.GRAPH_VIEW_CREATE:
        this.graphViews.create(action.id, action.label);
        break;
      case ActionType.GRAPH_VIEW_RENAME:
        this.graphViews.updateLabel(action.graphViewId, action.label);
        break;
      case ActionType.GRAPH_VIEW_DELETE:
        this.graphViews.remove(action.graphViewId);
        break;
      case ActionType.GRAPH_VIEW_TASK_ADD:
        return this.graphViews.addTask(
          action.graphViewId,
          action.taskId,
          action.position
        );
      case ActionType.GRAPH_VIEW_TASK_MOVE:
        const tasks =
          action.taskId instanceof Array ? action.taskId : [action.taskId];
        tasks.forEach(id =>
          this.graphViews.moveTask(action.graphViewId, id, action.moveBy)
        );
        break;
      case ActionType.GRAPH_VIEW_TASK_HIDE:
        this.graphViews.hideTasks(action.graphViewId, action.taskIds);
        break;

      case ActionType.TASK_CREATE:
        const result = this.tasks.create(action.id, action.title);
        if (result.t === "err") {
          return result;
        }

        if (action.dependsOn != null) {
          return this.dependencies.create(
            generateId(),
            action.id,
            action.dependsOn
          );
        }
        if (action.dependedOnBy != null) {
          return this.dependencies.create(
            generateId(),
            action.dependedOnBy,
            action.id
          );
        }
        this.updateUnblocked(action.id);
        break;

      case ActionType.TASK_RENAME:
        this.tasks.rename(action.id, action.title);
        break;

      case ActionType.TASK_DEPENDENCY_CREATE: {
        const result = this.dependencies.create(
          generateId(),
          action.task,
          action.dependsOn
        );
        this.updateUnblocked(action.task);
        return result;
      }

      case ActionType.TASK_DEPENDENCY_DELETE:
        const depId = this.dependencies.find(action.left, action.right);
        if (depId) {
          this.dependencies.delete(depId);
        }
        this.updateUnblocked(action.right);
        break;

      case ActionType.TASK_COMPLETE: {
        const deps = this.getDependenciesForTask(action.task);
        this.tasks.setStatus(
          action.task,
          action.complete ? "complete" : "none"
        );
        deps.dependedOnBy.forEach(this.updateUnblocked);
        break;
      }

      case ActionType.TASK_STATUS_UPDATE: {
        const deps = this.getDependenciesForTask(action.task);
        this.tasks.setStatus(action.task, action.status);
        deps.dependedOnBy.forEach(this.updateUnblocked);
        break;
      }

      case ActionType.TASK_DESCRIPTION_UPDATE:
        this.tasks.updateDescription(action.task, action.description);
        break;

      case ActionType.TASK_DELETE: {
        const deps = this.getDependenciesForTask(action.task);
        this.tasks.deleteTask(action.task);
        deps.dependedOnBy.forEach(this.updateUnblocked);
        break;
      }

      default:
        console.warn("Unhandled domain action", action);
    }
    return Result.ok(undefined);
  }

  saveAction(action: DomainAction): ActionResult {
    if (!this.store.isOpen.get()) {
      throw new Error("Attempt to save when no document open");
    }
    const result = this.applyAction(action);
    if (result.t === "err") {
      console.error("Save error", result);
      return result;
    }

    // Ensure we save the action to the log first
    this.store.eventLog.push(JSON.parse(JSON.stringify(action)));

    // persist the log
    localStorage.setItem("log", JSON.stringify(this.store.eventLog));

    this.store.isDirty.set(true);

    return result;
  }

  private isBlocked = (id: TaskId) => {
    const deps = this.getDependenciesForTask(id);

    for (let taskId of deps.dependsOn) {
      const task = expect(this.store.tasks.items.get(taskId));

      if (task.status.get() !== "complete") {
        // No need to keep checking once we've found a single blocker
        return true;
      }
    }
    return false;
  };

  updateUnblocked = action((id: TaskId) => {
    if (this.isBlocked(id)) {
      this.store.tasks.unblocked.delete(id);
    } else {
      this.store.tasks.unblocked.add(id);
    }
  });

  getDependenciesForTask = (taskId: TaskId) => {
    const task = expect(this.store.tasks.items.get(taskId));
    const dependsOn: TaskId[] = [];
    const dependedOnBy: TaskId[] = [];
    task.dependencies.forEach(depId => {
      const dep = expect(this.store.dependencies.items.get(depId));
      if (dep.left == taskId) {
        dependedOnBy.push(dep.right);
      } else if (dep.right == taskId) {
        dependsOn.push(dep.left);
      } else {
        console.error(
          "Searched for tasks related to " + taskId + ", found dep:",
          JSON.stringify(dep)
        );
        throw new Error("Invalid dependency");
      }
    });

    return { dependsOn, dependedOnBy };
  };

  getTopLevelTasks = (): Task[] => {
    return Array.from(this.store.tasks.items.values()).filter(t => {
      let hasDependant = false;
      t.dependencies.forEach(depId => {
        const a = expect(this.store.dependencies.items.get(depId));
        if (a.left === t.id) {
          // Something else depends on this task
          hasDependant = true;
        }
      });
      return !hasDependant;
    });
  };
}
