import * as React from "react";
import { Stack } from "../layout/Stack";
import { SelectionToolIcon, MoveToolIcon } from "../../components/Icons";
import { ToolbarButton } from "~view/ui/button/ToolbarButton";
import { Tool } from "~state/data/ui/graph_view";
import { useAppState } from "~app_provider";
import { observer } from "mobx-react-lite";

export const Toolbar = observer(() => {
  const app = useAppState();
  const activeTool = app.store.ui.graphView.activeTool.get();
  return (
    <Stack direction="column" spacing={0}>
      <ToolbarButton
        Icon={SelectionToolIcon}
        active={activeTool === Tool.SELECT}
        onClick={() => app.ui.graphView.onChooseTool(Tool.SELECT)}
      ></ToolbarButton>
      <ToolbarButton
        Icon={MoveToolIcon}
        active={activeTool === Tool.MOVE}
        onClick={() => app.ui.graphView.onChooseTool(Tool.MOVE)}
      ></ToolbarButton>
    </Stack>
  );
});
