import { Howl } from "howler";
import spriteWebm from "../assets/sfx/sprite.webm";
import spriteMp3 from "../assets/sfx/sprite.mp3";

export namespace SfxState {
  export type T = {
    sprite: Howl;
  };
  export enum Sound {
    INFO = "info",
    WARNING = "warning",
    ERROR = "error",
    ON = "on",
    OFF = "off",
    COMPLETE = "complete",
    SELECT = "select",
    ACCEPT = "accept",
    CANCEL = "cancel",
  }

  // TODO: Hack to fix loading audio files 4 times. Move this state back into
  // the namespace
  const defaultInstance = _init();

  export function _init(): T {
    return {
      sprite: new Howl({
        src: [spriteWebm, spriteMp3],
        sprite: {
          info: [0, 1500],
          warning: [1870, 1500],
          error: [3670, 1500],
          on: [5460, 1500],
          off: [7300, 1500],
          complete: [9250, 1500],
          select: [11200, 1500],
          accept: [13017, 1500],
          cancel: [14925, 1500],
        },
      }),
    };
  }

  export function init(): T {
    return defaultInstance;
  }

  export function play(state: T, sound: Sound) {
    state.sprite.play(sound);
  }
}
