import * as React from "react";
import { stylesheet } from "typestyle";

const styles = stylesheet({
  verticalCenter: {
    display: "flex",
    height: "100%",
    justifyContent: "space-around",
    flexDirection: "column",
  },
});

export const VerticalCenter: React.FunctionComponent = ({ children }) => (
  <div className={styles.verticalCenter}>{children}</div>
);
