import { action, observable } from "mobx";
import { TaskDependencyId, TaskId } from "../../../persistence/log/identifiers";
import { expect } from "../../../util";
import { DomainStore } from "../../data/domain";
import { Task } from "../../data/domain/tasks";
import { ActionErrorTag, ActionResult } from "../../../persistence/log/errors";
import { Result } from "../../../base/result";

export class TaskActions {
  store: DomainStore;

  constructor(store: DomainStore) {
    this.store = store;
  }

  create = action(
    (id: TaskId, title: string): ActionResult => {
      if (title.trim().length === 0) {
        return Result.err(ActionErrorTag.TASK_NAME_EMPTY);
      }

      this.store.tasks.items.set(id, {
        id,
        title: observable.box(title),
        description: observable.box(""),
        dependencies: observable.set(),
        status: observable.box("none"),
      });
      this.store.tasks.unblocked.add(id);
      return Result.ok(undefined);
    }
  );

  updateDescription = action((id: TaskId, description: string) => {
    const task = expect(this.store.tasks.items.get(id));
    task.description.set(description);
  });

  rename = action((id: TaskId, title: string) => {
    const task = expect(this.store.tasks.items.get(id));
    task.title.set(title);
  });

  deleteTask = action((id: TaskId) => {
    let deleteDeps = [] as TaskDependencyId[];

    this.store.tasks.unblocked.delete(id);

    // Remove task from all graph views
    for (const view of this.store.graphViews.items.values()) {
      view.taskPositions.delete(id);
    }

    // Remove dependencies
    this.store.dependencies.items.forEach(dep => {
      if (dep.right === id || dep.left === id) {
        deleteDeps.push(dep.id);
      }
    });
    deleteDeps.forEach(depId => {
      this.store.dependencies.items.delete(depId);
      this.store.tasks.items.forEach(t => {
        t.dependencies.delete(depId);
      });
    });
    this.store.tasks.items.delete(id);
  });

  setStatus = action(
    (id: TaskId, status: "none" | "in-progress" | "complete") => {
      const task = expect(this.store.tasks.items.get(id));
      task.status.set(status);
    }
  );

  findById(id: TaskId) {
    return this.store.tasks.items.get(id);
  }

  findByIds(ids: TaskId[]): Task[] {
    return ids.map(id => expect(this.store.tasks.items.get(id)));
  }

  findByTitle(query: string) {
    let tasks: Task[] = [];
    const lowercaseQuery = query.toLocaleLowerCase();
    this.store.tasks.items.forEach(t => {
      if (
        t.title
          .get()
          .toLocaleLowerCase()
          .includes(lowercaseQuery)
      ) {
        tasks.push(t);
      }
    });
    return tasks;
  }
}
