import { generateId, GraphViewId, TaskId } from "./identifiers";
import * as Actions from "./action_definitions";
import { ActionType, Position } from "./action_definitions";

export const createTask = (
  title: string,
  dependsOn?: TaskId,
  dependedOnBy?: TaskId
): Actions.CreateTaskAction => ({
  type: ActionType.TASK_CREATE,
  id: generateId(),
  title,
  dependsOn,
  dependedOnBy,
});

export const graphViewCreate = (
  label: string
): Actions.GraphViewCreateAction => ({
  id: generateId(),
  label,
  type: ActionType.GRAPH_VIEW_CREATE,
});

export const graphViewRename = (
  graphViewId: GraphViewId,
  label: string
): Actions.GraphViewRenameAction => ({
  type: ActionType.GRAPH_VIEW_RENAME,
  graphViewId,
  label,
});

export const graphViewDelete = (
  graphViewId: GraphViewId,
): Actions.GraphViewDeleteAction => ({
  type: ActionType.GRAPH_VIEW_DELETE,
  graphViewId,
});

export const graphViewTaskAdd = (
  graphViewId: GraphViewId,
  taskId: TaskId,
  position: Position
): Actions.GraphViewTaskAddAction => ({
  type: ActionType.GRAPH_VIEW_TASK_ADD,
  graphViewId,
  taskId,
  position,
});

export const graphViewTaskMove = (
  graphViewId: GraphViewId,
  taskIds: TaskId[],
  moveBy: Position
): Actions.GraphViewTaskMoveAction => ({
  type: ActionType.GRAPH_VIEW_TASK_MOVE,
  graphViewId,
  taskId: taskIds,
  moveBy,
});

export const graphViewTaskHide = (
  graphViewId: GraphViewId,
  taskIds: TaskId[]
): Actions.GraphViewTaskHideAction => ({
  type: ActionType.GRAPH_VIEW_TASK_HIDE,
  graphViewId,
  taskIds,
});

export const renameTask = (
  id: TaskId,
  title: string
): Actions.RenameTaskAction => ({
  type: ActionType.TASK_RENAME,
  id,
  title,
});

export const setTaskStatus = (
  task: TaskId,
  status: "none" | "in-progress" | "complete"
): Actions.UpdateStatusAction => ({
  type: ActionType.TASK_STATUS_UPDATE,
  task,
  status,
});

export const createTaskDependency = (
  task: TaskId,
  dependsOn: TaskId
): Actions.CreateDependencyAction => ({
  type: ActionType.TASK_DEPENDENCY_CREATE,
  task,
  dependsOn,
});

export const deleteTaskDependency = (
  left: TaskId,
  right: TaskId
): Actions.DeleteDependencyAction => ({
  type: ActionType.TASK_DEPENDENCY_DELETE,
  left,
  right,
});

export const deleteTask = (task: TaskId): Actions.DeleteTaskAction => ({
  type: ActionType.TASK_DELETE,
  task,
});

export const updateTaskDescription = (
  task: TaskId,
  description: string
): Actions.UpdateTaskDescriptionAction => ({
  type: ActionType.TASK_DESCRIPTION_UPDATE,
  task,
  description,
});
