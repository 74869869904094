import { IObservableValue, observable } from "mobx";
import { Vec2 } from "../../../../base/vec2";
import {
  TaskDependencyId,
  TaskId,
} from "../../../../persistence/log/identifiers";
import { TaskAnchor } from "../../domain/task_dependencies";
import { FuzzyFinderStore } from "../fuzzy_finder";

export type GraphMouseTarget =
  | { t: "background" }
  | { t: "task"; taskId: TaskId }
  | { t: "task_anchor"; taskId: TaskId; anchor: TaskAnchor.T };

type GraphViewState =
  | { t: "none" }
  | {
      t: "task_adding";
      position: Vec2.T;
      finder: FuzzyFinderStore<TaskId>;
      connectTo:
        | { t: "none" }
        | { t: "task"; taskId: TaskId; anchor: TaskAnchor.T };
    }
  | {
      // Creating or selecting a new task on the graph
      t: "new_dependency_adding";
      existingTask: TaskId;
      existingTaskAnchor: TaskAnchor.T;
      connectTo:
        | { t: "none" }
        | { t: "task"; task: TaskId }
        | { t: "space"; position: Vec2.T };
    }
  | {
      // Selecting from a task's existing dependencies to add to the graph
      t: "existing_dependency_adding";
      taskId: TaskId;
      anchor: TaskAnchor.T;
    }
  | { t: "selection_marquee"; start: Vec2.T; end: Vec2.T }
  | { t: "selection_moving"; start: Vec2.T; end: Vec2.T }
  | { t: "camera_moving" };

export enum BUTTON {
  LEFT = 0,
  RIGHT = 2,
  MIDDLE = 1,
}

type MouseState =
  | { t: "none" }
  | { t: "down"; start: Vec2.T; time: number; target: GraphMouseTarget };

export enum Tool {
  SELECT,
  MOVE,
}

export class GraphViewStore {
  state: IObservableValue<GraphViewState>;
  cameraOrigin: IObservableValue<Vec2.T>;
  viewSize: IObservableValue<Vec2.T>;
  mouseState: MouseState = { t: "none" };
  activeTool: IObservableValue<Tool>;

  constructor() {
    this.state = observable.box({ t: "none" });
    this.cameraOrigin = observable.box({ x: 0, y: 0 });
    this.viewSize = observable.box({ x: 100, y: 100 });
    this.activeTool = observable.box(Tool.SELECT);
  }
}
