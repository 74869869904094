import { DomainActions } from "../state/domain";
import { ExportJsonV1 } from "./schema_v1";
import { ActionType } from "../persistence/log/action_definitions";
import { GraphViewId, TaskId } from "../persistence/log/identifiers";
import { Result } from "~base/result";
import { ExportJson } from "./schema";

function doImportV1(file: ExportJsonV1, domain: DomainActions) {
  domain.store.isOpen.set(true);

  for (const task of file.tasks) {
    domain.saveAction({
      type: ActionType.TASK_CREATE,
      id: task.id as TaskId,
      title: task.title,
    });

    if (task.description != null) {
      domain.saveAction({
        type: ActionType.TASK_DESCRIPTION_UPDATE,
        task: task.id as TaskId,
        description: task.description,
      });
    }

    domain.saveAction({
      type: ActionType.TASK_STATUS_UPDATE,
      task: task.id as TaskId,
      status: task.status,
    });
  }

  for (const dep of file.dependencies) {
    domain.saveAction({
      type: ActionType.TASK_DEPENDENCY_CREATE,
      dependsOn: dep.left as TaskId,
      task: dep.right as TaskId,
    });
  }

  for (const graphView of file.graphViews) {
    domain.saveAction({
      type: ActionType.GRAPH_VIEW_CREATE,
      id: graphView.id as GraphViewId,
      label: graphView.label,
    });

    for (const taskPos of graphView.taskPositions) {
      domain.saveAction({
        type: ActionType.GRAPH_VIEW_TASK_ADD,
        graphViewId: graphView.id as GraphViewId,
        taskId: taskPos.taskId as TaskId,
        position: taskPos.pos,
      });
    }
  }

  // We've imported this file, so it's not really dirty until a further change is made
  domain.store.isDirty.set(false);
}

export function doImport(file: ExportJson, domain: DomainActions) {
  if (file.version === "v1") {
    doImportV1(file, domain);
  } else {
    throw new Error("Unsupported version");
  }
}

async function blobToText(blob: Blob): Promise<string> {
  const reader = new FileReader();
  reader.readAsText(blob);
  return new Promise((resolve, reject) => {
    reader.onerror = () => {
      reject(reader.error);
    };

    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
}

export namespace Importer {
  export type ImportError = { t: "parse-error" };
  export type ImportSuccess = { importedTasks: number };

  export async function importFile(
    file: File,
    domain: DomainActions
  ): Promise<Result.T<ImportSuccess, ImportError>> {
    const contents = JSON.parse(await blobToText(file));
    if (contents.version === "v1") {
      // TODO: Safely parse this and error if unexpected
      const exportJson = contents as ExportJsonV1;
      doImportV1(exportJson, domain);
      return Result.ok({ importedTasks: exportJson.tasks.length });
    }

    return Result.err({ t: "parse-error" });
  }
}
