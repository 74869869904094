import { IObservableValue, observable, ObservableMap } from "mobx";
import { GraphViewId, TaskId } from "../../../../persistence/log/identifiers";
import { Vec2 } from "../../../../base/vec2";

export type GraphView = {
  graphViewId: GraphViewId;
  label: IObservableValue<string>;
  taskPositions: ObservableMap<TaskId, Vec2.T>;
};

export class GraphViewStore {
  items: ObservableMap<GraphViewId, GraphView>;

  constructor() {
    this.items = observable.map();
  }

  reset() {
    this.items.clear();
  }
}
