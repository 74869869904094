import * as React from "react";
import { AppActions } from "~state";
import { AppStore } from "~state/data";
import { createDummyStore } from "~store/store";

let store = new AppStore(createDummyStore());
let app = new AppActions(store, { loadFromLocalStorage: true });

export const AppStateProvider = React.createContext<AppActions>(app);

export const useAppState = (): AppActions => React.useContext(AppStateProvider);
